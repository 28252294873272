import React, { Component } from "react";
import "./../Login.css";
import conf from './../../config';
import { Auth } from "aws-amplify";
import SuccessMark from '../../lib/images/svgs/ICON_circle-check_green.svg';
import FailMark from '../../lib/images/svgs/ICON_circle-x_red.svg';
import { reverseAgencyRole, uniqueID, returnDateTimeWithDashes } from "../global/formatter";
import * as AgencyRoleConstants from '../constants/AgencyRoleConstants';
import { showSpinner } from './../global/loader';
import { withRouter } from "../withRouter";

const serviceHeader = {
  'Content-Type': conf.agencyDBService.header.contentType,
  'x-api-key': conf.agencyDBService.header.key,
  'Connection': conf.agencyDBService.header.connection
};
var agencyServiceEndpoint = new URL(conf.agencyDBService.endpoint);


class ReviewRegistrationRequests extends Component {

  state = {
    users: {
      UserList: [
        {
          approved: "",
          user_id: "",
          first_name: "",
          last_name: "",
          name: "",
          role: "",
          roleformatted: ""
        }
      ]
    },
    fields: {
      showManageUsersModal: false, showSingleSelectModal: true, showManageUsersDeclineModal: false
    },
    allChecked: 0,
    checkboxCount: 0,
    nonActiveCheckboxCount: 0,
    noCheckboxesChecked: true,
    keyIndex: 0,
    reason_for_disapproval: '',
    openErrorSnackBar: false,
    isErrorFlag: '',
    isDisabledDeclineBtn: true,
    dataflag: 0,
    LoadedUserIDList: [{ key: "", value: "" }],
    pendingApprovals: true,
    error_display: false,
    isAEPAdmin: false
  }

  callAEPAdminData = () => {
    const query = "SELECT distinct u.user_id, u.token, u.first_name, u.last_name, u.phone, u.role, a.name, L.street_address1, L.street_address2, L.city, L.state, L.zip_code, L.name as location FROM Agency.gaa_user u INNER JOIN Agency.agency a on u.agency_id = a.agency_id INNER JOIN Agency.agency_location L ON a.agency_id = L.agency_id and L.agency_location_id = cast ( u.token_flag as int8) where u.approved_user_flag = ''";
    this.loadUserData(query);
  }

  callAgencyAdminData = () => {
    const agency_id = this.props.auth.user["attributes"].zoneinfo;
    const query = "SELECT distinct u.user_id, u.token, u.first_name, u.last_name, u.phone, u.role, a.name, L.street_address1, L.street_address2, L.city, L.state, L.zip_code, L.name as location FROM Agency.gaa_user u INNER JOIN Agency.agency a on u.agency_id = a.agency_id INNER JOIN Agency.agency_location L ON a.agency_id = L.agency_id and L.agency_location_id = cast ( u.token_flag as int8) where u.approved_user_flag = '' AND u.role = '" + AgencyRoleConstants.AGENCY_USER_ROLE + "' AND u.agency_id = '" + agency_id + "'";
    this.loadUserData(query);
  }

  loadUserData = (query) => {
    let tableName = ""// "GAA_USER"
    const params = {
      TableName: tableName, CommandType: "CUSTOM", Query: query
    }
    Object.keys(params).forEach(key => agencyServiceEndpoint.searchParams.append(key, params[key]));
    (async () => {
      try {
        const response = await fetch(agencyServiceEndpoint, {
          method: 'POST',
          headers: serviceHeader
        })
        const json_resp = await response.json();
        console.log("this is response from api call agency admin: ");
        console.log(await json_resp);
        console.log(!json_resp.ResponseMetadata);
        if (!json_resp.ResponseMetadata) {
          this.setState({
            users: {
              "UserList": await json_resp.map(x => Object.assign({}, x, { "approved": "P", "roleformatted": reverseAgencyRole(x.role) })),
            },
            dataflag: 1,
            LoadedUserIDList: {
              users:
              {
                "UserList": await json_resp
              }
            }
          });
        }
        console.log(this.state);
      }
      catch (error) {
        console.log(error);
      }
      showSpinner(false);
    })();
  }

  async componentDidMount() {
    console.log("\n *STARTING* \n");
    showSpinner(true);
    await Auth.currentAuthenticatedUser();
    console.log(this.props);
    let role = "";
    let userGroup = "";
    const groupName = conf.cognito.USER_POOL_ID + '_aep';
    if (this.props.auth.user != null) {
      userGroup = this.props.auth.user.signInUserSession.accessToken.payload['cognito:groups'];
      role = this.props.auth.user["attributes"].nickname;
      console.log(role);
      if (String(role) === AgencyRoleConstants.AGENCY_USER_ROLE) {
        this.props.navigate("/myprofile");
        showSpinner(false);
      }
      else if (String(role) === AgencyRoleConstants.AGENCY_ADMIN_ROLE) {
        console.log("Agency admin");
        this.callAgencyAdminData();
      }
      else if (String(userGroup) === groupName) {
        console.log("AEP admin");
        this.setState({isAEPAdmin: true});
        this.callAEPAdminData();
      }
      else {
        this.props.navigate("/accountsummary");
        showSpinner(false);
      }
    }
  }

  setValues = (node_list) => {
    let flag = false;
    let counter = 0;
    for (let i = 0; i < node_list.length; i++) {
      if (node_list[i].type.toString().toLowerCase() === "checkbox") {
        if (node_list[i].checked)
          counter++;
      }
    }
    if (counter > 0)
      flag = true;
    this.setState({ noCheckboxesChecked: !flag, checkboxCount: counter });
  }

  selectionChanged = () => {
    this.setValues(document.getElementsByTagName('input'));
  }

  callEmailServiceDisapprove = (action, userinfo) => {
    const shadowservicegenericURL = new URL(conf.shadowServiceGenericURL.url + "agencyemailsender");
    const body = { "HashLink": uniqueID(),"AccountNo": "na", "Template": "disapproveusers","StreetAddress1": userinfo["street_address1"],"StreetAddress2": userinfo["street_address2"],
                    "City": userinfo["city"],"State": userinfo["state"],"ZipCode": userinfo["zip_code"],"Preheader": "AEP Agency Registration Declined!","Subject": "AEP Agency Registration Declined!",
                    "SubscriberKey": userinfo["user_id"]+""+uniqueID(),"EmailAddress": userinfo["user_id"], "ChannelMemberID": "10449858","conformationcode": "0000", 
                    "AgencyURL": conf.oauth.redirectSignIn+"/register","Agency": userinfo["name"],"Name": userinfo["first_name"]+" "+userinfo["last_name"],"Role": userinfo["role"],
                    "Phone": userinfo["phone"],"PendingUserEmail": "","PasswordResetExt": "", "Reason": this.state.reason_for_disapproval };
    console.log(body);
    (async () => {
      try {
        const response = await fetch(shadowservicegenericURL, {
          method: 'POST',
          headers: serviceHeader,
          body: JSON.stringify(body)
        });
        const json_resp = await response.json();
        console.log(json_resp);
        this.closeModal(action);
        showSpinner(false);
      }
      catch (error) {
        console.log(error);
        this.closeModal(action);
        showSpinner(false);        
      }
    })();
  }

  cognitodeleteuser = (username, action, userinfo) => {
    const shadowservicegenericURL = new URL(conf.shadowServiceGenericURL.url + "cognitops");
    const params = { Operation: "DELETEUSER", Username: username }
    Object.keys(params).forEach(key => shadowservicegenericURL.searchParams.append(key, params[key]));
    (async () => {
      try {
        const response = await fetch(shadowservicegenericURL, {
          method: 'POST',
          headers: serviceHeader
        })
        const json_resp = await response.json();
        console.log("this is response from api call: " + JSON.stringify(json_resp));
        if (json_resp.Status === undefined) {
          if (json_resp["ResponseMetadata"].HTTPStatusCode === 200) {
            let newvalue = this.state.users;
            newvalue['UserList'][this.state.keyIndex]['approved'] = 'D'
            this.setState({
              users: newvalue
            });
            this.callEmailServiceDisapprove(action, userinfo);
          }
          else
            this.callError(action);
        }
        else {
          if (json_resp.Status.toLowerCase().includes('fail'))
            this.callError(action);
        }
      }
      catch (error) {
        this.callError(action);
      }
      showSpinner(false);
    })();
  }

setSuccessBulkUpdates = (action) => {
    let node_list = document.getElementsByTagName('input');
    var newvaluemerged = []
    for (let i = 0; i < node_list.length; i++) {
      var newvalue = this.state.users.UserList[node_list[i].id];
      if (node_list[i].type.toString().toLowerCase() === "checkbox") {
        if (node_list[i].checked) {
          newvalue['approved'] = "A";
          console.log(newvalue);
          this.callEmailService(action, newvalue);
        }       
      }
      newvaluemerged.push(newvalue);
    }
    console.log(this.state.nonActiveCheckboxCount + newvaluemerged.length);
    this.setState({
      users: { "UserList": newvaluemerged }, nonActiveCheckboxCount: this.state.nonActiveCheckboxCount + newvaluemerged.length
    },
      function () {
        this.setValues(document.getElementsByTagName('input'));
    });
    showSpinner(false);
}

  updateDeletedUsersInfo = (userlist, reason_for_disapproval, action, token, userinfo) => {
    let tableName = ""// "GAA_USER"
    // let userlist = event.userlist
    agencyServiceEndpoint = new URL(conf.agencyDBService.endpoint);
    let formattedquery = "UPDATE agency.DELETED_USERS SET reason_for_disapproval = '" + String(reason_for_disapproval).replaceAll("'","''").trim() + "' where user_id in ('" + userlist + "') AND token = '" + token + "'"
    console.log(formattedquery + "updateDeletedUsersInfo query::::::::::::");
    const params2 = {
      TableName: tableName, CommandType: "CUSTOM", Query: formattedquery
    }
    Object.keys(params2).forEach(key => agencyServiceEndpoint.searchParams.append(key, params2[key]));
    (async () => {
      try {
        const response = await fetch(agencyServiceEndpoint, {
          method: 'POST',
          headers: serviceHeader
        })
        const json_resp = await response.json();
        console.log("this is response from api call: " + JSON.stringify(json_resp));
        if (json_resp.Status === undefined) {
          if (json_resp["ResponseMetadata"].HTTPStatusCode === 200)
            this.deleteUserRecordDecline(userlist, action, userinfo);
          else
            this.callError(action);
        }
        else {
          if (json_resp.Status.toLowerCase().includes('fail'))
            this.callError(action);
        }
      }
      catch (error) {
        console.log(error);
        this.callError(action);
      }
    })();
  }

  createRecordDeletedUsers = (userlist, reason_for_disapproval, action, token, userinfo) => {
    let tableName = ""// "GAA_USER"
    agencyServiceEndpoint = new URL(conf.agencyDBService.endpoint);
    let formattedquery = "INSERT INTO agency.DELETED_USERS SELECT * FROM AGENCY.GAA_USER WHERE user_id = '" + userlist + "'"
    console.log(formattedquery + "createRecordDeletedUsers query::::::::::::::");
    const params = {
      TableName: tableName, CommandType: "CUSTOM", Query: formattedquery
    }
    Object.keys(params).forEach(key => agencyServiceEndpoint.searchParams.append(key, params[key]));
    (async () => {
      try {
        const response = await fetch(agencyServiceEndpoint, {
          method: 'POST',
          headers: serviceHeader
        })
        const json_resp = await response.json();
        console.log("this is response from api call: " + JSON.stringify(json_resp) + ":" + json_resp.Status);
        if (json_resp.Status === undefined) {
          if (json_resp["ResponseMetadata"].HTTPStatusCode === 200)
            this.updateDeletedUsersInfo(userlist, reason_for_disapproval, action, token, userinfo);
          else
            this.callError(action);
        }
        else {
          if (json_resp.Status.toLowerCase().includes('fail'))
            this.callError(action);
        }
      }
      catch (error) {
        this.callError(action);
        console.log(error);
      }
    })();
  }

  deleteUserRecordDecline = (userlist, action, userinfo) => {
    let tableName = "";// "GAA_USER"
    agencyServiceEndpoint = new URL(conf.agencyDBService.endpoint);
    let formattedquery = "DELETE from agency.GAA_USER where user_id in ('" + userlist + "')";
    console.log(formattedquery);
    const params = { TableName: tableName, CommandType: "CUSTOM", Query: formattedquery };
    Object.keys(params).forEach(key => agencyServiceEndpoint.searchParams.append(key, params[key]));
    (async () => {
      try {
        const response = await fetch(agencyServiceEndpoint, {
          method: 'POST',
          headers: serviceHeader
        })
        const json_resp = await response.json();
        // console.log("this is response from api call: ")
        console.log(JSON.stringify(json_resp));
        if (json_resp.Status === undefined) {
          if (json_resp["ResponseMetadata"].HTTPStatusCode === 200)
            this.cognitodeleteuser(userlist, action, userinfo);
          else
            this.callError(action);
        }
        else {
          if (json_resp.Status.toLowerCase().includes('fail'))
            this.callError(action);
        }
      }
      catch (error) {
        console.log(error);
        this.callError(action);
      }
    })();
  }

  callError = (action) => {
    showSpinner(false);
    if (action === null) {
      console.log(action);
      this.setState({ error_display: true });
    }
    else {
      this.setState({ isErrorFlag: true, error_display: true });
      this.closeModal(action);
    }
  }

  selectionAllChanged = () => {
    let node_list = document.getElementsByTagName('input');
    console.log("Node list")
    console.log(node_list);
    console.log(this.state.allChecked);

    if (this.state.allChecked)
      this.setState({
        allChecked: 0,
        noCheckboxesChecked: true,
        checkboxCount: 0
      }, () => {
        this.setCheckBoxes(node_list);
      });
    else
      this.setState({
        allChecked: 1,
        noCheckboxesChecked: false,
        checkboxCount: node_list.length
      }, () => {
        this.setCheckBoxes(node_list);
      });
  }

  showInfo = (flag, key, action) => {
    let fields = this.state.fields;
    if (key !== null) {
      this.setState({ keyIndex: key });
    }
    switch (action) {
      case 'approved':
        fields["showManageUsersModal"] = true;
        fields["showSingleSelectModal"] = flag;
        break;
      case 'declined':
        fields["showManageUsersDeclineModal"] = true;
        break;
      default:
        break;
    }
    this.setState({ fields });
  }

  closeModal = (action) => {
    let fields = this.state.fields;
    switch (action) {
      case 'approved':
        fields["showManageUsersModal"] = false;
        break;
      case 'declined':
        fields["showManageUsersDeclineModal"] = false;
        break;
      default:
        break;
    }
    this.setState({ fields });
  }

  setCheckBoxes = (node_list) => {
    for (let i = 0; i < node_list.length; i++) {
      if (node_list[i].type.toString().toLowerCase() === "checkbox" && !node_list[i].hidden) {
        node_list[i].checked = this.state.allChecked === 0 ? false : true;
      }
    }
  }

  approveSelected = (action, userinfo) => {
    let node_list = document.getElementsByTagName('input');
    var userlistlocal = [];
    var roleListLocal = [];
    for (let i = 0; i < node_list.length; i++) {
      if (node_list[i].type.toString().toLowerCase() === "checkbox") {
        if (node_list[i].checked) {
          userlistlocal.push(this.state.LoadedUserIDList.users.UserList[node_list[i].id].user_id);
          roleListLocal.push(this.state.LoadedUserIDList.users.UserList[node_list[i].id].role);
        }
      }
    }
    const formatteduserlist = userlistlocal.join("','");
    const roleuserlist = roleListLocal.join(",");
    console.log(JSON.stringify(roleuserlist) + "::::" + JSON.stringify(formatteduserlist));
    this.callBulkCognitoUpdateRole(formatteduserlist, true, userlistlocal, roleuserlist, action, userinfo);
  }

  callEmailService = (action, userinfo) => {
    const shadowservicegenericURL = new URL(conf.shadowServiceGenericURL.url + "agencyemailsender");
    const body = { "HashLink": uniqueID(),"AccountNo": "na", "Template": "approved","StreetAddress1": userinfo["street_address1"],"StreetAddress2": userinfo["street_address2"],
                    "City": userinfo["city"],"State": userinfo["state"],"ZipCode": userinfo["zip_code"],"Preheader": "You've Been Approved!","Subject": "You've Been Approved!",
                    "SubscriberKey": userinfo["user_id"]+""+uniqueID(),"EmailAddress": userinfo["user_id"], "ChannelMemberID": "10449858","conformationcode": "0000", 
                    "AgencyURL": conf.oauth.redirectSignIn,"Agency": userinfo["name"],"Name": userinfo["first_name"]+" "+userinfo["last_name"],"Role": userinfo["role"],
                    "Phone": userinfo["phone"],"PendingUserEmail": "","PasswordResetExt": "" };
    console.log(body);
    (async () => {
      try {
        const response = await fetch(shadowservicegenericURL, {
          method: 'POST',
          headers: serviceHeader,
          body: JSON.stringify(body)
        });
        const json_resp = await response.json();
        console.log(json_resp);
        this.closeModal(action);
        showSpinner(false);
      }
      catch (error) {
        console.log(error);
        this.closeModal(action);
        showSpinner(false);        
      }
    })();
}

callBulkCognitoUpdateRole = (userlist, isBulkUpdate, userlistlocal, groupname, action, userinfo) => {
  const shadowservicegenericURL = new URL(conf.shadowServiceGenericURL.url + "cognitops");
  const params = { Operation: "BULKADDUSERTOGROUP", Username: userlistlocal, GroupName: groupname }
  Object.keys(params).forEach(key => shadowservicegenericURL.searchParams.append(key, params[key]));
  (async () => {
    try {
      const response = await fetch(shadowservicegenericURL, {
        method: 'POST',
        headers: serviceHeader
      })
      const json_resp = await response.json();
      console.log("this is response from api call: " + JSON.stringify(json_resp));
      if (json_resp.Status === undefined) {
        if (json_resp["ResponseMetadata"].HTTPStatusCode === 200)
          this.callDBUpdates(userlist, isBulkUpdate, action, userinfo);
        else
          this.callError(action);
      }
      else {
        if (json_resp.Status.toLowerCase().includes('fail'))
            this.callError(action);
      }
    }
    catch (error) {
      console.log(error);
      this.callError(action);
    }
  })();
}

callDBUpdates = (userlist, isBulkUpdate, action, userinfo) => {
  let tableName = "", approved_by_user= "";// "GAA_USER"
  // let userlist = event.userlist
  if(this.state.isAEPAdmin)
    approved_by_user = String(this.props.auth.user["username"]).split(".")[2]+" : "+this.props.auth.user["attributes"].email;
  else
    approved_by_user = this.props.auth.user["attributes"].given_name+" "+this.props.auth.user["attributes"].family_name+" : "+this.props.auth.user["attributes"].email;
  agencyServiceEndpoint = new URL(conf.agencyDBService.endpoint);
  let formattedquery = "UPDATE agency.GAA_USER SET approved_user_flag = 'Y', approved_by= '"+ approved_by_user+"', approved_timestamp= '"+returnDateTimeWithDashes() +"' where user_id in ('" + userlist + "')"
  console.log(formattedquery)
  const params2 = {
    TableName: tableName, CommandType: "CUSTOM", Query: formattedquery
  }
  Object.keys(params2).forEach(key => agencyServiceEndpoint.searchParams.append(key, params2[key]));
  (async () => {
    try {
      const response = await fetch(agencyServiceEndpoint, {
        method: 'POST',
        headers: serviceHeader
      });
      const json_resp = await response.json();
      console.log("this is response from api call: " + JSON.stringify(json_resp));
      if (json_resp.Status === undefined) {
        if (json_resp["ResponseMetadata"].HTTPStatusCode === 200 && isBulkUpdate)
          this.setSuccessBulkUpdates(action);
        else if (json_resp["ResponseMetadata"].HTTPStatusCode === 200 && !isBulkUpdate) {
          let newvalue = this.state.users;
          newvalue['UserList'][this.state.keyIndex]['approved'] = 'A';
          this.callEmailService(action, userinfo);
          this.setState({
            users: newvalue, nonActiveCheckboxCount: this.state.nonActiveCheckboxCount + 1
          }, function () {
            this.setValues(document.getElementsByTagName('input'));
          });
          showSpinner(false);
        }
        else
          this.callError(action);
      }
      else {
        if (json_resp.Status.toLowerCase().includes('fail'))
          this.callError(action);
      }
    }
    catch (error) {
      console.log(error);
      this.callError(action);
    }
  })();
}

callCognitoUpdateRole = (username, isBulkUpdate, userlistlocal, groupname, action, userinfo) => {
  const shadowservicegenericURL = new URL(conf.shadowServiceGenericURL.url + "cognitops");
  const params = { Operation: "ADDUSERTOGROUP", Username: username, GroupName: groupname }
  Object.keys(params).forEach(key => shadowservicegenericURL.searchParams.append(key, params[key]));
  (async () => {
    try {
      const response = await fetch(shadowservicegenericURL, {
        method: 'POST',
        headers: serviceHeader
      })
      const json_resp = await response.json();
      console.log("this is response from api call: " + JSON.stringify(json_resp));
      if (json_resp.Status === undefined) {
        if (json_resp["ResponseMetadata"].HTTPStatusCode === 200)
          this.callDBUpdates(username, isBulkUpdate, action, userinfo);
        else
          this.callError(action);
      }
      else {
        if (json_resp.Status.toLowerCase().includes('fail'))
          this.callError(action);
      }
    }
    catch (error) {
      this.callError(action);
      console.log(error);
    }
  })();
}

  submitFunction = (action) => {
    const singleSelect = this.state.fields["showSingleSelectModal"];
    const userinfo = this.state.users.UserList[this.state.keyIndex];
    console.log(JSON.stringify(userinfo));
    showSpinner(true);
    switch (action) {
      case 'approved':
        console.log("correctly entered approved loop : singleSelect" + singleSelect);
        if (singleSelect)
          this.callCognitoUpdateRole(userinfo["user_id"], false, null, userinfo["role"], action, userinfo);
        else
          this.approveSelected(action, userinfo);
        break;
      case 'declined':
        this.setState({ isDisabledDeclineBtn: true });
        console.log("correctly entered declined loop" + this.state.reason_for_disapproval + "reason_for_disapproval");
        this.createRecordDeletedUsers(userinfo["user_id"], this.state.reason_for_disapproval, action, userinfo["token"], userinfo);
        break;
      default:
        this.closeModal(action);
        showSpinner(false);
        break;
    }
  }

  setReasonDecline = event => {
    if (event.target.value !== '')
      this.setState({ isDisabledDeclineBtn: false, [event.target.name]: event.target.value });
    else
      this.setState({ isDisabledDeclineBtn: true, [event.target.name]: event.target.value });
  }

  render() {

    return (
      <section>
        {/* main content */}
        <div class="container mt-st" >
          {<div>
            {this.state.error_display &&
                <div class="row">
                    <div class="col-12 error mb-30">
                        <div class="error">
                            <p class="mb-0">We're sorry, something went wrong, please try again. If issue persists please contact the Customer Operations Center, at <a href="tel:18009564237" aria-label="8 0 0. 9 5 6. 4 2 3 7">1-800-956-4237</a> for assistance.</p>
                        </div>
                    </div>
                </div>
            }
            <h1>Review Registration Requests</h1>
            {this.state.dataflag === 0 ? (<div>No user records to display</div>) : (<div>
              <table class="table-row-dividers w-100 manage-requests">
                <thead>
                  <tr class="row mb-15 ml-n1">
                    <th class="col-sm-9 col-md-9 col-lg-9" >
                      <b>
                        <button class="btn button-without-border ml-0 pl-0" onClick={this.selectionAllChanged} disabled={document.getElementsByTagName('input').length === 0 && this.state.nonActiveCheckboxCount !== 0}>{(this.state.checkboxCount === (this.state.users.UserList.length - this.state.nonActiveCheckboxCount)) ? 'Deselect all users' : 'Select all users'}</button>
                      </b>
                    </th>
                    <th class="col-sm-3 col-md-3 col-lg-3">
                      <b>
                        <button class="btn button-without-border pl-0" onClick={() => this.showInfo(false, null, 'approved')} disabled={this.state.noCheckboxesChecked}>Approve Selected Users</button>
                      </b>
                    </th>
                  </tr>
                  <tr class="row ml-n1">
                    <th class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                      <b class="ml-30">User Details</b>
                    </th>
                    <th class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                      <b>Agency</b>
                    </th>
                    <th class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                      <b>Location</b>
                    </th>
                    <th class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                      <b>Role</b>
                    </th>
                    <th class="col-xs-3 col-sm-3 col-md-3 col-lg-3"></th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(this.state.users.UserList).map(([key, userinfo]) => (
                    <tr class="row ml-n1" key={key}>
                      {userinfo!== null && userinfo!== undefined && <td class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                        <div class="checkbox display-inline-block img-modal-toggle">
                          {userinfo.approved === "P" && (<input name="user-list" type="checkbox" class="users-checkbox" id={key} onClick={this.selectionChanged} />)}
                          <label htmlFor={key} className={"align-middle img-modal-toggle manage-requests " + ((userinfo.approved === "A" || userinfo.approved === "D") ? 'cursor-default' : 'cursor-pointer')} >
                            <div className={"word-break " + ((userinfo.approved === "A" || userinfo.approved === "D") ? 'ml-30' : '')} >
                              <p class="mb-0">{userinfo.first_name},&nbsp;{userinfo.last_name}</p>
                              <p>{userinfo.user_id}</p>
                            </div>
                          </label>
                        </div>
                      </td> }
                      <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                        <span class="text-align-checkbox">{userinfo.name}</span>
                      </td>
                      <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                        <span class="text-align-checkbox">{userinfo.location}</span>
                      </td>
                      <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                        <span class="text-align-checkbox">{userinfo.roleformatted}</span>
                      </td>
                      <td class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                        {(userinfo!== undefined && userinfo!== null && userinfo.approved === "P") &&
                          <div class="mt-10">
                            <button class="btn btn-secondary mr-20" onClick={() => this.showInfo(true, key, 'approved')}>Approve</button>
                            {this.state.fields["showManageUsersModal"] &&
                              (
                                <div class="modal-open">
                                  <div class="modal fade show display-block" id="centeredModal" tabIndex="-1" role="dialog" aria-modal="true">
                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                      <div class="modal-content">
                                        <div class="modal-header">
                                          <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={() => this.closeModal('approved')}>
                                            <span aria-hidden="true"></span>
                                          </button>
                                        </div>
                                        <div class="modal-body">
                                          <h4>Are you sure?</h4><br />
                                          <p>You have selected to approve&nbsp;
                                      {this.state.fields["showSingleSelectModal"] ? 1 : this.state.checkboxCount}
                                      &nbsp;requests. Once approved, any corrections must be made individually. </p> <br />
                                          <div class="button-block col-12">
                                            <div class="row">
                                              <button class="btn btn-primary mr-20" onClick={this.submitFunction.bind(this, 'approved')} >Yes, approve them </button>
                                              <button class="btn button-without-border pl-0" onClick={() => this.closeModal('approved')}>No, go back</button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="modal-backdrop fade show"></div>
                                </div>
                              )}
                            <button class="btn button-without-border p-0" onClick={() => this.showInfo(null, key, 'declined')} >Decline</button>
                            {this.state.fields["showManageUsersDeclineModal"] &&
                              (
                                <div class="modal-open">
                                  <div class="modal fade show display-block" id="centeredModal" tabIndex="-1" role="dialog" aria-modal="true">
                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                      <div class="modal-content">
                                        <div class="modal-header">
                                          <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={() => this.closeModal('declined')}>
                                            <span aria-hidden="true"></span>
                                          </button>
                                        </div>
                                        <div class="modal-body">
                                          <h4 class="mb-25">Decline Registration Request</h4>
                                          <p class="mb-1">You are about to decline the request for&nbsp;
                                      <b>{this.state.users.UserList[this.state.keyIndex]["first_name"] + " " + this.state.users.UserList[this.state.keyIndex]["last_name"] + "."}</b>
                                          </p>
                                          <p class="mb-40">Please briefly explain the reason and solution so that the user can fix when registering again. This will be included in the email sent to the user.</p>
                                          <div class="form-row">
                                            <div class="col">
                                              <div id="decline_request_wrapper" class="form-group aep-custom contains-textarea">
                                                <div class="input-group">
                                                  <textarea name="reason_for_disapproval" rows="2" cols="20" id="reason_for_disapproval" class="form-control" maxLength="200" data-field-type="text" placeholder="200 character limit." value={this.state.reason_for_disapproval} onChange={this.setReasonDecline}></textarea>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="button-block col-12">
                                            <div class="row">
                                              <button class="btn btn-primary mr-20" onClick={this.submitFunction.bind(this, 'declined')} disabled={this.state.isDisabledDeclineBtn}>Decline and send</button>
                                              <button class="btn button-without-border pl-0" onClick={() => this.closeModal('declined')}>Cancel</button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="modal-backdrop fade show"></div>
                                </div>
                              )}
                          </div>}
                        {(userinfo!== undefined && userinfo!== null && userinfo.approved == "A") && <div class="mt-10"><img class="float-left" src={SuccessMark}></img><b class="float-left ml-10 mt-1">Approved!</b></div>}
                        {(userinfo!== undefined && userinfo!== null && userinfo.approved == "D") && <div class="mt-10"><img class="float-left" src={FailMark}></img><b class="float-left ml-10 mt-1">Declined!</b></div>}
                        {/* <Snackbar vertical='bottom' horizontal='center' open={this.handleClickSnackBar} close={this.handleCloseSnackbar} message='Error in processing' /> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div class="row ml-n1">
                <div class="col-12 approval-row">
                  <button class="btn btn-primary" onClick={() => this.showInfo(false, null, 'approved')} disabled={this.state.noCheckboxesChecked}>Approve Selected</button>
                </div>
              </div>
            </div>)}
          </div>}
        </div>

      </section>)
  }
}


export default withRouter(ReviewRegistrationRequests);