import React, { Component } from "react";
import { OnlyDigits, addHypensPhonenumber, returnDateTimeWithDashes } from '../global/formatter';
import config from '../../config.json';
import { showSpinner } from '../global/loader';
import StatesList from '../global/StatesList';
import { PatternFormat } from 'react-number-format';
import { withRouter } from "../withRouter";
const invalidPhoneMsg = <span>Please enter a valid 10-digit phone number.</span>;
const noChangeMsg = <span class='error'>Please enter location details.</span>;
const defaultMsg = <p class="mb-0 error">We're sorry, something went wrong, please try again. If issue persists please contact <u class="color-dark-blue">agency@aep.com</u> for assistance.</p>;
const serviceHeader = {
    'Content-Type': config.agencyDBService.header.contentType,
    'x-api-key': config.agencyDBService.header.key,
    'Connection': config.agencyDBService.header.connection
};
var agencyServiceEndpoint = new URL(config.agencyDBService.endpoint);

class UpdateLocationDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            location: this.props.location.state.info.name,
            address1: this.props.location.state.info.street_address1,
            address2: this.props.location.state.info.street_address2,
            city: this.props.location.state.info.city,
            state: this.props.location.state.info.state,
            zip: this.props.location.state.info.zip_code,
            phone: this.props.location.state.info.phone,
            localtionId: this.props.location.state.info.agency_location_id,
            agencyId: this.props.location.state.agencyInfo.agency_id,
            errorMsg: '',
            isError: true,
            disabledFlag: false,
            statearray: StatesList.statearray
        }

    }

    handlelocationname = (event) => {
        this.setState({ errorMsg: '', isError: false }) // clear any error messages
        const locationname = this.state.location;
        var value = event.target.value;
        if (value !== locationname) {
            this.setState({ location: value });
        }
    }

    handleaddress1 = (event) => {
        this.setState({ errorMsg: '', isError: false }) // clear any error messages
        const address1 = this.state.address1;
        var value = event.target.value;
        if (value !== address1) {
            this.setState({ address1: value });
        }
    }

    handleaddress2 = (event) => {
        this.setState({ errorMsg: '', isError: false }) // clear any error messages
        const address2 = this.state.address2;
        var value = event.target.value;
        if (value !== address2) {
            this.setState({ address2: value });
        }
    }

    handlecity = (event) => {
        this.setState({ errorMsg: '', isError: false }) // clear any error messages
        const city = this.state.city;
        var value = event.target.value;
        if (value !== city) {
            this.setState({ city: value });
        }
    }

    handlestate = (event) => {
        const { value } = event.target;
        this.setState({ errorMsg: '', isError: false }) // clear any error messages
        const statename = this.state.state;
        var newValue = value;
        if (newValue !== statename) {
            this.setState({ state: newValue });
        }
    }

    handlezipcode = (event) => {
        this.setState({ errorMsg: '', isError: false }) // clear any error messages
        const zip = this.state.zip;
        var value = event.target.value;
        if (value !== zip) {
            this.setState({ zip: value });
        }
    }

    handlePhoneInputChange = (values) => {
        this.setState({ errorMsg: '', isError: false }) // clear any error messages
        const { value } = values;
        let num = '';
        let phone = this.state.phone;
        var newphone = this.state.newphone;
        //var phone = this.state.phone;
        phone = value;
        this.setState({ phone });
        if (value != this.state.phone) {
            newphone = value;
            this.setState({ newphone }, () => {
                num = OnlyDigits(newphone); // remove formatting
                //phone = '+1' + num; // set database value
                this.setState({ phone });
            });
        }
    }

    handleSubmit = () => {
        var location = this.state.location;
        var phone = this.state.phone;
        var address1 = this.state.address1
        var city = this.state.city
        var state = this.state.state
        var zip = this.state.zip
        var errorMsg = this.state.errorMsg;

        if (location !== "" && phone !== "" && address1 !== "" && city !== "" && state !== "" && zip !== "") {
            if (OnlyDigits(phone).length == 10) {
                showSpinner(true);
                this.setState({ isError: false, disabledFlag: true });
                this.UpdateAgencyLocationdetails();
            }
            else {
                errorMsg = invalidPhoneMsg;
                this.setState({ errorMsg, disabledFlag: false });
                showSpinner(false);
            }
        }
        else {
            errorMsg = noChangeMsg;
            this.setState({ errorMsg, isError: true, disabledFlag: false });
            showSpinner(false);
        }
    }

    callCognitoBulk = () => {
        const shadowservicegenericURL = new URL(config.shadowServiceGenericURL.url + "cognitops");
        let address = "";
        address += this.state.address1;
        if (this.state.address2 !== '' && this.state.address2 !== 'None')
            address += ' ' + this.state.address2;
        address += ' ' + this.state.city;
        address += ' ' + this.state.state;
        address += ' ' + this.state.zip;

        const params = { Operation: "UPDATEATTRIBUTESBULK", ChangeType: "AgencyLocation", agency_id: this.state.agencyId, agency_location_id: this.state.localtionId };
        params["Userattributes"] = '[{ "Name":"name",  "Value": "' + this.state.location + '" },' +
            '{ "Name":"address",  "Value": "' + address + '" },' +
            '{"Name":"picture",  "Value": "' + this.state.city + '" },' +
            '{"Name":"website",  "Value": "' + this.state.state + '" }]';
        Object.keys(params).forEach(key => shadowservicegenericURL.searchParams.append(key, params[key]));
        (async () => {
            try {
                const response = await fetch(shadowservicegenericURL, {
                    method: 'POST',
                    headers: serviceHeader
                })
                const json_resp = await response.json();
                console.log("this is response from api call: " + JSON.stringify(json_resp));
                if (json_resp.Status === undefined) {
                    if (json_resp["ResponseMetadata"].HTTPStatusCode === 200)
                        this.props.navigate("/ManageAgencies", {state: { 'userUpdated': true }});
                    else
                        this.callError();
                }
                else {
                    if (json_resp.Status.toLowerCase().includes('fail'))
                        this.callError();
                }
            }
            catch (error) {
                console.log(error);
                this.callError();
            }
            finally {
                showSpinner(false);
            }
        })();
    }

    UpdateAgencyLocationdetails = () => {
        let tableName = ""// "agency_location"
        let formattedquery = "";
        formattedquery = "UPDATE Agency.agency_location SET name = '" + String(this.state.location).replaceAll("'","''").trim() + "', phone = '" + this.state.phone + "', street_address1 = '" + String(this.state.address1).replaceAll("'","''").trim() + "', street_address2 = '" + String(this.state.address2).replaceAll("'","''").trim() + "', city = '" + String(this.state.city).replaceAll("'","''").trim() + "', state = '" + this.state.state + "', zip_code = '" + this.state.zip + "' , last_update_user = '" + this.state.username + "' , last_update_timestamp = '" + returnDateTimeWithDashes() + "' where agency_location_id = '" + this.state.localtionId + "'"
        console.log(formattedquery)
        const params2 = {
            TableName: tableName, CommandType: "CUSTOMES", Query: formattedquery
        }
        Object.keys(params2).forEach(key => agencyServiceEndpoint.searchParams.append(key, params2[key]));
        (async () => {
            try {
                const response = await fetch(agencyServiceEndpoint, {
                    method: 'POST',
                    headers: serviceHeader
                });
                const json_resp = await response.json();
                console.log("this is response from api call: " + JSON.stringify(json_resp));
                if (json_resp.Status === undefined) {
                    if (json_resp["ResponseMetadata"].HTTPStatusCode === 200)
                        this.callCognitoBulk();
                    else
                        this.callError();
                }
                else
                    this.callError();
            }
            catch (error) {
                console.log(error);
                this.callError();
            }
        })();
    }

    callError = () => {
        this.setState({ errorMsg: defaultMsg, isError: true, disabledFlag: false });
        showSpinner(false);
    }

    cancel = (event) => {
        this.props.navigate("/manageagencies");
    }

    componentDidMount = () => {
        console.log("history: ", this.props.location);
        console.log("rowData: ", this.props.location.state);
        let username = this.props.auth.user.signInUserSession.accessToken.payload.username.split('.')[2];
        this.setState({ username });
    }

    render() {
        return (
            <section>
                <div class='container mt-st'>
                    <h1>EDIT AGENCY LOCATION</h1>
                    {this.state.isError &&
                        <div class='mb-4'>{this.state.errorMsg}</div>
                    }
                    <div class="row">
                        <div class="col-md-8">
                            <div class="bordered-form">
                                <div class="form-row">
                                    <div class="col-sm-5">
                                        <div class="form-group aep-custom moveable-label">
                                            <div class="input-group">
                                                <input type="text" id="tblocation" value={this.state.location} class="form-control" onChange={this.handlelocationname} />
                                                <label htmlFor="tblocation">Location Name</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-5">
                                        <div class="form-group aep-custom moveable-label">
                                            <div class="input-group">
                                                <PatternFormat format="+1 (###) ###-####" mask="_" name="phone_num" id='tbphone' class="form-control" data-field-type="number" value={this.state.phone} placeholder="Phone Number" onValueChange={(values) => this.handlePhoneInputChange(values)} allowEmptyFormatting />
                                                <label htmlFor='tbphone'>Phone Number</label>
                                                {this.state.errorMsg === invalidPhoneMsg && (<span id="tbphoneInvalid" class="invalid-feedback validator"> {this.state.errorMsg}</span>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row mb-4">
                                    <div class="col-sm-5">
                                        <div class="form-group aep-custom moveable-label">
                                            <div class="input-group">
                                                <input type="text" id="tbaddress1" value={this.state.address1} class="form-control" onChange={this.handleaddress1} />
                                                <label htmlFor="tbaddress1">Street Address</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-5">
                                        <div class="form-group aep-custom moveable-label">
                                            <div class="input-group">
                                                <input type="text" id="tbaddress2" value={this.state.address2} class="form-control" onChange={this.handleaddress2} />
                                                <label htmlFor="address2">Additional Address</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-5">
                                        <div class="form-group aep-custom moveable-label">
                                            <div class="input-group">
                                                <input type="text" id="tbcity" value={this.state.city} class="form-control" onChange={this.handlecity} />
                                                <label htmlFor="tbcity">City</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group aep-custom moveable-label">
                                            <div class="input-group">
                                                {/* <input type="text" id="tbstate" value={this.state.state} class="form-control" onChange={this.handlestate} />
                                                <label htmlFor="tbstate">State</label> */}
                                                <select
                                                    class="form-control"
                                                    name="state_code"
                                                    id="tbstate"
                                                    placeholder="State"
                                                    data-field-type="dropdown"
                                                    value={this.state.state}
                                                    onChange={this.handlestate}
                                                    required
                                                >
                                                    <option value="" class="blank">-</option>
                                                    {this.state.statearray.map((row, key) => (
                                                        <option key={row.state} value={row.state}>
                                                            {row.state}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label htmlFor="tbstate" class="align-label">State</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-group aep-custom moveable-label">
                                            <div class="input-group">
                                                <input type="text" id="tbzip" value={this.state.zip} class="form-control" onChange={this.handlezipcode} />
                                                <label htmlFor="tbzip">Zip Code</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class='form-row'>
                                    <div class="col-12">
                                        <button type='submit' class="btn btn-primary mb-10" onClick={this.handleSubmit} disabled={this.state.isError || this.state.disabledFlag}>Save changes</button>
                                    </div>
                                    <div class="col-12">
                                        <a href="/manageagencies" onClick={this.cancel}>Cancel</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4"></div>
                    </div>
                </div>
            </section>
        )
    }
}


export default withRouter(UpdateLocationDetails);