import React, { Component } from "react";
import conf from './../../config';
import { Auth } from "aws-amplify";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import SuccessMark from '../../lib/images/svgs/ICON_circle-check_green.svg';
import Add_Logo from '../../lib/images/svgs/ICON_plus_blue.svg';
import Remove_Logo from '../../lib/images/svgs/ICON_x_blue.svg';
import { showSpinner } from './../global/loader';
import * as AgencyRoleConstants from '../constants/AgencyRoleConstants';
import { withRouter } from "../withRouter";

const serviceHeader = {
  'Content-Type': conf.agencyDBService.header.contentType,
  'x-api-key': conf.agencyDBService.header.key,
  'Connection': conf.agencyDBService.header.connection
};
var agencyServiceEndpoint = new URL(conf.agencyDBService.endpoint);

let updateAgencyFlag = false;

class ManageAgencies extends Component {

  agency_accordion = React.createRef();
  state = {
    selectState: "",
    stateList: [],
    expanded: false,
    agencyList: [],
    agencyListDisplay: [],
    locationsList: [],
    agencyByState: {}
      }

  async componentDidMount() {
    showSpinner(true);
    await Auth.currentAuthenticatedUser();
    let role = "", userGroup = "";
    const groupName = conf.cognito.USER_POOL_ID + '_aep';
    if (this.props.auth.user != null) {
      userGroup = this.props.auth.user.signInUserSession.accessToken.payload['cognito:groups'];
      role = this.props.auth.user["attributes"].nickname;
      console.log(this.props.location.state);
      if (String(role) === AgencyRoleConstants.AGENCY_USER_ROLE) {
        this.props.navigate("/accountsummary");
        showSpinner(false);
      }
      else if (String(role) === AgencyRoleConstants.AGENCY_ADMIN_ROLE) {
        this.callStateGroupingAgencyList(false);
        this.callAgencyAdminData();
      }
      else if (String(userGroup) === groupName) {
        this.setState({ isAepAdmin: true });
        this.callStateGroupingAgencyList(true);
        this.callAEPAdminData();
      }
      else {
        this.props.navigate("/accountsummary");
        showSpinner(false);
      }
    }
  }

  callStateGroupingAgencyList = (flag) => {
    let query ="";
    if(flag)
      query = "SELECT AL.agency_id, AL.state from Agency.agency_location AL GROUP BY AL.state, AL.agency_id";
    else
      query = "SELECT AL.agency_id, AL.state from Agency.agency_location AL WHERE AL.agency_id ='"+this.props.auth.user["attributes"].zoneinfo+"' GROUP BY AL.state, AL.agency_id";
    let tableName = "" //"GAA_USER"
    const params = {
      TableName: tableName, CommandType: "CUSTOM", Query: query
    }
    Object.keys(params).forEach(key => agencyServiceEndpoint.searchParams.append(key, params[key]));
    (async () => {
      try {
        const response = await fetch(agencyServiceEndpoint, {
          method: 'POST',
          headers: serviceHeader
        });
        const json_resp = await response.json();
        console.log('JSON: ', json_resp);
        if (json_resp.Status === undefined) {
          if (!json_resp.ResponseMetadata) {
            console.log("inside");
            let result = {};
            json_resp.map((element) => {
              console.log(element.agency_id)
              console.log(element.state)
              if(result[element.state] === undefined) {
                let list =[];
                list.push(element.agency_id);
                result[element.state] = list;
              }
              else {
                let temp = result[element.state];
                temp.push(element.agency_id);
                result[element.state] = temp;
              }
            });
            console.log(result);
            let stateListTemp= [];
            Object.keys(result).map(element => {
              stateListTemp.push({'name': element});
            });
            this.setState({agencyByState: result, stateList: stateListTemp });
          }
        }
      }
      catch (error) {
        console.log(error);
      }
    })();
  }

  callAEPAdminData = () => {

    console.log("aep admin");
    const query = "SELECT distinct name, agency_id, status, agency_type, created_by, created_date FROM Agency.agency";
    this.loadUserData(query, true);
  }

  callAgencyAdminData = () => {
    console.log("agency admin");
    const user_id = this.props.auth.user["attributes"].email;
    const query = "SELECT U.agency_id, A.name FROM Agency.gaa_user U INNER JOIN Agency.agency A ON A.agency_id = cast (U.agency_id as int8) WHERE u.user_id = '" + user_id + "'";
    this.loadUserData(query, true);
  }

  callLocationAgency = (agency_id) => {
    const query = "SELECT agency_location_id, name, street_address1, street_address2, city, state, zip_code, phone FROM Agency.agency_location WHERE agency_id = '" + agency_id + "'";
    this.loadUserData(query, false);
  }

  loadUserData = (query, flag) => {
    showSpinner(true);
    let tableName = "" //"GAA_USER"
    const params = {
      TableName: tableName, CommandType: "CUSTOM", Query: query
    }
    Object.keys(params).forEach(key => agencyServiceEndpoint.searchParams.append(key, params[key]));
    (async () => {
      try {
        const response = await fetch(agencyServiceEndpoint, {
          method: 'POST',
          headers: serviceHeader
        });
        const json_resp = await response.json();
        console.log('JSON: ', json_resp);
        if (json_resp.Status === undefined) {
          if (!json_resp.ResponseMetadata && flag)
            this.setState({ agencyList: json_resp, agencyListDisplay: json_resp });
          else if (!json_resp.ResponseMetadata && !flag)
            this.setState({ locationsList: json_resp });
        }
      }
      catch (error) {
        console.log(error);
      }
      showSpinner(false);
    })();
  }

  handleChangeState = (value, name) => {
    console.log(value);
    console.log(name);
    if(value === 'all')
      this.setState({agencyListDisplay: this.state.agencyList});
    else {
      const agencyIDState = this.state.agencyByState[value];
      console.log(agencyIDState);
      let filteredList = [];
      this.state.agencyList.slice().filter(element => agencyIDState.includes(element.agency_id)).map(filteredElement => {
        filteredList.push(filteredElement);
      });
      this.setState({agencyListDisplay: filteredList});
    }  
  }

  addAgency = () => {
    this.props.navigate("/addAgency");
  }

  handleChangeAccordion = (index) => (event, isExpanded) => {
    if (!updateAgencyFlag) {
      showSpinner(true);
      console.log("inside accordion sub");
      const agency_id = this.state.agencyListDisplay[index].agency_id;
      this.callLocationAgency(agency_id);
      this.setState({ expanded: isExpanded ? index : false });
    }
  }

  handleLocations = (key, info, agencyInfo) => {
    console.log("handle locations" + key);
    console.log(info);
    this.props.navigate('/UpdateLocationDetails', { state:{
        "info": info,
        "agencyInfo": agencyInfo
    } });
  }

  updateAgency = (key, agencyInfo) => {
    console.log("update agency" + key);
    updateAgencyFlag = true;
    this.props.navigate(
      '/UpdateAgencyName', 
      { state:{
        "agencyInfo": agencyInfo
      }});
  }

  addLocation = (key, agencyInfo) => {
    console.log("add loc" + key);
    updateAgencyFlag = true;
    this.props.navigate("/addLocation", {state: {"agencyInfo": agencyInfo} });
  }

  render() {
    return (
      <section>
        <div class="container mt-st">
          <div class='row mb-40'>
            <div class='col-sm-11 col-xl-10 mb-30'>
              <h1>Manage Agencies</h1>
            </div>
            {(this.props.location.state !== undefined && this.props.location.state !== null && this.props.location.state.addAgencyUpdate ) &&
              <div class="mb-15 display-inline-block">
              <img class="float-left ml-20" src={SuccessMark} alt="Success Mark"></img><p class="float-left mt-1 ml-10">Success! New Agency has been created successfully.</p>
              </div>}
            {(this.props.location.state !== undefined && this.props.location.state !== null && this.props.location.state.userUpdated ) &&
              <div class="mb-15 display-inline-block">
              <img class="float-left ml-20" src={SuccessMark} alt="Success Mark"></img><p class="float-left mt-1 ml-10">Success! Location information has been updated successfully.</p>
              </div>}
            {(this.props.location.state !== undefined && this.props.location.state !== null && this.props.location.state.locationUpdate) &&
              <div class="mb-15 display-inline-block">
              <img class="float-left ml-20" src={SuccessMark} alt="Success Mark"></img><p class="float-left mt-1 ml-10">Success! New Location for the agency has been created successfully.</p>
              </div>}
              {(this.props.location.state !== undefined && this.props.location.state !== null && this.props.location.state.agencyUpdated ) &&
              <div class="mb-10 display-inline-block">
              <img class="float-left" src={SuccessMark} alt="Success Mark"></img><p class="float-left mt-1 ml-20">Success! Agency information has been updated successfully.</p>
            </div>
            }
          </div>
          {this.state.agencyList.length !== 0 ? <div>
            <div class="row">
              <div class="col-sm-4 col-md-4">
                <div role="form">
                  <div class="wrapper-block">
                    <div class="form-row mb-20">
                      <div class="col-12 col-md-9">
                        <div id="agency_select_wrapper" class="form-group aep-custom moveable-label">
                          <div class="input-group">
                            <select name="selectState" defaultValue={this.state.selectState} id='selectState' class="form-control custom-select has-value" onChange={(event) => this.handleChangeState(event.target.value, event.target.name)} data-field-type="dropdown">
                              <option value="all" class="blank">All</option>
                              {this.state.stateList.map((state) => (
                                <option key={state.name} value={state.name}>
                                  {state.name}
                                </option>
                              ))}
                            </select>
                            <label htmlFor='selectState'>State</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {this.state.isAepAdmin && <div class="col-sm-8 col-sm-8 text-right">
                <button class="btn btn-primary" onClick={this.addAgency}>Add agency</button>
              </div>}
            </div>
            <hr class="divider-sm mb-40" />
            <div class="accordion-wrapper">
              {Object.entries(this.state.agencyListDisplay).map(([key, agencyInfo]) => (
                <div class="wrapper-block" key={key}>
                  <Accordion expanded={this.state.expanded === key} onChange={this.handleChangeAccordion(key)} ref={this.agency_accordion}>
                    <AccordionSummary
                      expandIcon={this.state.expanded === key ? <img src={Remove_Logo} alt="Comtract Icon" style={{ width: '25px', marginLeft: '-3px' }} /> : <img src={Add_Logo} alt="Expand Icon" style={{ width: '15px' }} />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header" >
                      <div name="agency-name" onClick={this.updateAgency.bind(this, key, agencyInfo)} class="cursor-pointer agency-name-update">{agencyInfo.name}</div>
                      {this.state.isAepAdmin && <button class="btn button-anchor sub-location-aligment" onClick={this.addLocation.bind(this, key, agencyInfo)}>Add Location</button>}
                    </AccordionSummary>
                    <AccordionDetails>
                      {Object.entries(this.state.locationsList).map(([key, locationsInfo]) => (
                        <div key={key} onClick={this.handleLocations.bind(this, key, locationsInfo, agencyInfo)} class="cursor-pointer width-100 sub-accordion-custom">{locationsInfo.name}</div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))}</div>

          </div> : "No records to display"}
        </div>
      </section>
    )
  }
}


export default withRouter(ManageAgencies);