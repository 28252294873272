import React, { Component } from 'react';
import { showSpinner } from './../global/loader';
import conf from './../../config';
import { Auth } from "aws-amplify";
import { dateWithDashes, uniqueID } from "../global/formatter";
import { PatternFormat } from 'react-number-format';
import { FormControl } from "react-bootstrap";
import StatesList from './../global/StatesList';
import { withRouter } from '../withRouter';

const serviceHeader = {
    'Content-Type': conf.agencyDBService.header.contentType,
    'x-api-key': conf.agencyDBService.header.key,
    'Connection': conf.agencyDBService.header.connection
};
const defaultMsg = <p class="mb-0 error">We're sorry, something went wrong, please try again. If issue persists please contact <u class="color-dark-blue">agency@aep.com</u> for assistance.</p>;
var agencyServiceEndpoint = new URL(conf.agencyDBService.endpoint);

class AddLocation extends Component {

    state = {
        agencyID: "",
        agency: "",
        username: "",
        fields: {
            agency_location: "",
            street_address: "",
            street_address2: "",
            zip_code: "",
            city: "",
            USState: "",
            phone_num: "",
            date: "",
            errors: {},
            touched: {},
            isDisabledInfoFlag: true
        },
        isError: false,
        errorMsg: '',
        states: StatesList.statearray

    }

    async componentDidMount() {
        showSpinner(true);
        await Auth.currentAuthenticatedUser();
        let userGroup = "";
        const groupName = conf.cognito.USER_POOL_ID + '_aep';
        if (this.props.auth.user != null) {
            userGroup = this.props.auth.user.signInUserSession.accessToken.payload['cognito:groups'];
            if (String(userGroup) === groupName) {
                let user = this.props.auth.user.signInUserSession.accessToken.payload.username.split('.')[2];
                if (this.props.location.state !== null && this.props.location.state !== undefined) {
                    console.log(this.props.location.state.agencyInfo);
                    this.setState({
                        agency: this.props.location.state.agencyInfo.name,
                        agencyID: this.props.location.state.agencyInfo.agency_id,
                        username: user
                    });
                }
            }
            else
                this.props.navigate("/accountsummary");
            showSpinner(false);
        }
    }

    callAddLocation = () => {
        showSpinner(true);
        let fields = this.state.fields;
        fields["isDisabledInfoFlag"] = true;
        this.setState({fields});
        let timestamp = new Date().toUTCString();
        const query = "INSERT INTO agency.agency_location(agency_location_id,agency_id,name,street_address1,street_address2,city,state,zip_code,status,user_access_review_date,created_by,created_date,last_update_user,last_update_timestamp,phone)" +
            "VALUES(" + uniqueID() + "," + this.state.agencyID + ",'" + String(this.state.fields["agency_location"]).replaceAll("'","''").trim() + "','" + String(this.state.fields["street_address"]).replaceAll("'","''").trim() + "','" + String(this.state.fields["street_address2"]).replaceAll("'","''").trim() + "','" + String(this.state.fields["city"]).replaceAll("'","''").trim() + "','" + this.state.fields["USState"] + "','" + this.state.fields["zip_code"] + "','A','" + dateWithDashes(timestamp) + "','" + this.state.username + "','" + dateWithDashes(timestamp) + "','" + this.state.username + "','" + dateWithDashes(timestamp) + "','" + this.state.fields["phone_num"] + "')";
        console.log(query)
        this.loadUserData(query);
    }

    loadUserData = (query) => {
        let tableName = "agency_location" //"GAA_USER"
        const params = {
            TableName: tableName, CommandType: "CUSTOMES", Query: query
        }
        Object.keys(params).forEach(key => agencyServiceEndpoint.searchParams.append(key, params[key]));
        (async () => {
            try {
                const response = await fetch(agencyServiceEndpoint, {
                    method: 'POST',
                    headers: serviceHeader
                })
                const json_resp = await response.json();
                console.log('JSON: ', json_resp);
                if (json_resp.Status === undefined) {
                    if (json_resp["ResponseMetadata"].HTTPStatusCode === 200) {
                        this.props.navigate("/manageagencies", {state: { locationUpdate: true }});
                        showSpinner(false);
                    }
                    else
                        this.callError();
                }
                else
                    this.callError();
            }
            catch (error) {
                console.log(error);
                this.callError();
            }
        })();
    }

    callError = () => {
        let fields = this.state.fields;
        fields["isDisabledInfoFlag"] = false;
        this.setState({ isError: true, errorMsg: defaultMsg , fields});
        showSpinner(false);
    }

    handleStateChange = event => {
        console.log("Handle change")
        const { value } = event.target;
        let fields = this.state.fields;
        fields["USState"] = value;
        fields["touched"]["USState"] = true;
        this.setState({ fields },
            () => { this.validateField("USState", value) });
    }

    handleChange = event => {
        console.log("Handle change")
        const { name, value } = event.target;
        let fields = this.state.fields;
        fields[name] = value;
        console.log(name + " has changed")
        if (name !== "street_address2")
            fields["touched"][name] = true;
        this.setState({ fields },
            () => { this.validateField(name, value) });
    }

    handleStateChange = event => {
        console.log("Handle change")
        const { value } = event.target;
        let fields = this.state.fields;
        fields["USState"] = value;
        fields["touched"]["USState"] = true;
        this.setState({ fields },
            () => { this.validateField("USState", value) });
    }

    handleChangePhoneNum = (values) => {
        const { value } = values;
        let fields = this.state.fields;
        fields["phone_num"] = value
        fields["touched"]["phone_num"] = true;
        this.setState({ fields },
            () => { this.validateField("phone_num", value) });
    }

    setValidFlag = () => {
        let fields = this.state.fields;
        let flag = true;
        for (var key of Object.keys(fields["errors"])) {
            if (fields["errors"][key] !== '') {
                flag = false;
                console.log("errors existing:")
                console.log(fields["errors"])
                break;
            }
        }
        console.log("Flag")
        console.log(flag)
        if (!flag)
            fields["isDisabledInfoFlag"] = true;
        else
            fields["isDisabledInfoFlag"] = false;
        this.setState({ fields });
    }

    cancelAddLocation = () => {
        this.props.navigate("/manageagencies");
    }

    validateField = (fieldName, value) => {
        let fields = this.state.fields;
        const isEmpty = value.length === 0;
        switch (fieldName) {
            case 'city':
                if (isEmpty)
                    fields["errors"]["city"] = "Please enter a valid city.";
                else
                    fields["errors"]["city"] = "";
                break;
            case 'zip_code':
                if (isEmpty || value.length !== 5)
                    fields["errors"]["zip_code"] = "Please enter a valid zip code.";
                else
                    fields["errors"]["zip_code"] = "";
                break;
            case 'USState':
                if (isEmpty || value === '')
                    fields["errors"]["USState"] = "Please enter a valid state.";
                else
                    fields["errors"]["USState"] = "";
                break;
            case 'agency_location':
                if (isEmpty)
                    fields["errors"]["agency_location"] = "Please enter an agency location name.";
                else
                    fields["errors"]["agency_location"] = "";
                break;
            case 'phone_num':
                if (isEmpty)
                    fields["errors"]["phone_num"] = "Please enter a phone number.";
                else if (value.length < 10)
                    fields["errors"]["phone_num"] = "Please enter 10 digits";
                else
                    fields["errors"]["phone_num"] = "";
                break;
            case 'street_address':
                if (isEmpty)
                    fields["errors"]["street_address"] = "Please enter the street address.";
                else
                    fields["errors"]["street_address"] = "";
                break;
            default:
                break;
        }
        this.setState({ fields }, () => {
            this.setValidFlag();
        });
    }

    render() {
        return (
            <section className="section">
                <h1>ADD LOCATION TO {this.state.agency}</h1>
                {this.state.isError &&
                    <div class='mb-4'>{this.state.errorMsg}</div>
                }
                <div class="row">
                    <div class="col-sm-8">
                        <div class="bordered-form">
                            <div class="row mb-4">
                                <div class="col-12">
                                    <h3 class="mb-4">Please select "Save Location" once complete.</h3>
                                </div>
                            </div>
                            <div class="form-row mb-3">
                                <div class="col-sm-6 col-12 col-md-6">
                                    <div id="locationname" class="form-group aep-custom moveable-label">
                                        <div class="input-group">
                                            <input name="agency_location" type="text" required id='agency' class="form-control" data-field-type="text" value={this.state.fields["agency_location"]} onChange={this.handleChange} placeholder="Location Name" />
                                            <label htmlFor='agency'>Location Name</label>
                                        </div>
                                        {this.state.fields["errors"]["agency_location"] !== '' && (<span id="agncylocation" className="invalid-feedback validator" data-validate-field="agency_location" >{this.state.fields["errors"]["agency_location"]}</span>)}
                                    </div>
                                </div>
                            </div>

                            <div class="form-row mb-2">
                                <div class="col-12 col-sm-6 col-md-6">
                                    <div id="StreetAddress" class="form-group aep-custom moveable-label">
                                        <div class="input-group">
                                            <input name="street_address" type="text" required id='StreetAddress' class="form-control" data-field-type="text" value={this.state.fields["street_address"]} onChange={this.handleChange} placeholder="Street Address" />
                                            <label htmlFor='StreetAddress'>Street Address</label>
                                        </div>
                                        {this.state.fields["errors"]["street_address"] !== '' && (<span id="streetAddress" className="invalid-feedback validator" data-validate-field="street_address" >{this.state.fields["errors"]["street_address"]}</span>)}
                                    </div>
                                </div>

                                <div class="col-12 col-sm-6 col-md-6">
                                    <div id="StreetAddress2" class="form-group aep-custom moveable-label">
                                        <div class="input-group">
                                            <input name="street_address2" type="text" required id='streetAddress2' class="form-control" data-field-type="text" value={this.state.fields["street_address2"]} onChange={this.handleChange} placeholder="Optional Street Address" />
                                            <label htmlFor='streetAddress2'>Optional Street Address</label>
                                        </div>
                                        <span class="form-field-helper">This is usually an apt. suite, unit, etc.</span>
                                        {this.state.fields["errors"]["street_address2"] !== '' && (<span id="streetAddress2" className="invalid-feedback validator" data-validate-field="StreetAddress2" >{this.state.fields["errors"]["street_address2"]}</span>)}
                                    </div>
                                </div>
                            </div>

                            <div class="form-row mb-3">
                                <div class="col-12 col-sm-6 col-md-6">
                                    <div id="City" class="form-group aep-custom moveable-label">
                                        <div class="input-group">
                                            <input name="city" type="text" required id='city' class="form-control" data-field-type="text" value={this.state.fields["city"]} onChange={this.handleChange} placeholder="City" />
                                            <label htmlFor='city'>City</label>
                                        </div>
                                        {this.state.fields["errors"]["city"] !== '' && (<span id="city" className="invalid-feedback validator" data-validate-field="city" >{this.state.fields["errors"]["city"]}</span>)}
                                    </div>
                                </div>
                                <div class="col-sm-3 col-md-3">
                                    <div class="form-group aep-custom moveable-label">
                                        <div class="input-group">
                                            <FormControl as="select" onChange={(e) => this.handleStateChange(e)}>
                                                <option value="" class="blank">Select a State</option>
                                                {this.state.states.map((e, key) => {
                                                    return <option key={key} value={e.state}>{e.state}</option>;
                                                })}</FormControl>
                                            <label htmlFor='ddlstate'>State</label>
                                        </div>
                                        {this.state.fields["errors"]["USState"] !== '' && (<span id="" className="invalid-feedback validator" data-validate-field="USState" >{this.state.fields["errors"]["USState"]}</span>)}
                                    </div>
                                </div>
                                <div class="col-12 col-sm-3 col-md-3">
                                    <div id="Zip" class="form-group aep-custom moveable-label">
                                        <div class="input-group">
                                            <input name="zip_code" type="number" required id='ZipCode' class="form-control" data-field-type="text" value={this.state.fields["zip_code"]} onChange={this.handleChange} placeholder="Zip Code" />
                                            <label htmlFor='ZipCode'>Zip Code</label>
                                        </div>
                                        {this.state.fields["errors"]["zip_code"] !== '' && (<span id="" className="invalid-feedback validator" data-validate-field="ZipCode" >{this.state.fields["errors"]["zip_code"]}</span>)}
                                    </div>
                                </div>
                            </div>

                            <div class="form-row mb-4">
                                <div class="col-12 col-sm-6 col-md-6">
                                    <div id="cphContentMain_phone_num_cphContentMain_phone_num_wrapper" class="form-group aep-custom moveable-label">
                                        <div class="input-group">
                                            <PatternFormat format="+1 (###) ###-####" mask="_" name="phone_num" required id='cphContentMain_phone_num' class="form-control" data-field-type="number" value={this.state.fields["phone_num"]} placeholder="Phone Number" onValueChange={(values) => this.handleChangePhoneNum(values)} />
                                            <label htmlFor='cphContentMain_phone_num'>Phone Number</label>
                                        </div>
                                        {this.state.fields["errors"]["phone_num"] !== '' && (<span id="cphContentMain_phone_num_ctl02" class="invalid-feedback validator" data-validate-field="cphContentMain_phone_num" > {this.state.fields["errors"]["phone_num"]}</span>)}
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12 mb-3 col-md-3">
                                    <button class="btn btn-primary mr-20" onClick={this.callAddLocation} disabled={this.state.fields["isDisabledInfoFlag"] || Object.keys(this.state.fields["touched"]).length !== 6}>Save location</button>
                                </div>
                                <div class="col-12">
                                    <button class="btn button-without-border" onClick={this.cancelAddLocation} style={{
                                        fontSize: '17px', padding: '0 0 17px',
                                        textTransform: 'none', lineHeight: '2.3077', borderWidth: 0
                                    }}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}


export default withRouter(AddLocation);