import React, { Component } from 'react';
import { Container, Icon, Image, Menu } from 'semantic-ui-react';
import "./Navbar.css";
import { Auth } from 'aws-amplify';
import * as AgencyRoleConstants from './constants/AgencyRoleConstants';
import '../aeputilities-styles/styles/scss/site.scss';
import CorpLogo from '../components/Images/AEP-logo.jpg';
import config from '../config.json'
import { showSpinner } from './global/loader';
import { NavLink } from "react-router-dom";
import { withRouter } from "./withRouter";


class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideProfile: true, hideManageUsers: true, hideReports: true, hideAssistance: true,
      manageUsersToggle: false,
      toggleIsOpen: false,
    };
    // Create a ref for the dropdown menu
    this.dropdownRef = React.createRef();
  }

  callRole = () => {
    if (this.props.auth["isAuthenticated"]) {
      let role = "";
      console.log(this.props.auth);
      if (this.props.auth.user != null)
        role = this.props.auth.user["attributes"].nickname;
      console.log(role === AgencyRoleConstants.AGENCY_USER_ROLE);
      console.log(role === AgencyRoleConstants.AGENCY_ADMIN_ROLE);
      this.setState({
        hideManageUsers: (role === AgencyRoleConstants.AGENCY_USER_ROLE), hideReports: (role === AgencyRoleConstants.AGENCY_USER_ROLE), hideAssistance: false, hideProfile: false
      });
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.auth["isAuthenticated"] !== this.props.auth["isAuthenticated"]) {
      const user = await Auth.currentAuthenticatedUser();
      console.log(user);
      this.callRole();
    }
  }

  async componentDidMount() {
    const path = this.props.location.pathname
    var user = await Auth.currentAuthenticatedUser();
    console.log("NAVBAR");
    console.log("user: ", user);
    let userGroup = "";
    if (this.props.auth.user !== null) {
      userGroup = this.props.auth.user.signInUserSession.accessToken.payload['cognito:groups'];
      const groupName = config.cognito.USER_POOL_ID + '_aep';
      console.log(userGroup + ":::" + groupName);
      if (String(userGroup) === AgencyRoleConstants.AGENCY_ADMIN_ROLE) {
        console.log("AGENCY_ADMIN_ROLE: ", String(userGroup) === AgencyRoleConstants.AGENCY_ADMIN_ROLE);
        this.setState({
          hideProfile: false, hideAssistance: false, hideReports: false, hideManageUsers: false
        });
      }
      else if ("AGENCY_USER_ROLE: ", String(userGroup) === AgencyRoleConstants.AGENCY_USER_ROLE) {
        console.log(String(userGroup) === AgencyRoleConstants.AGENCY_USER_ROLE);
        this.setState({
          hideProfile: false, hideAssistance: false, hideReports: true, hideManageUsers: true
        });
      }
      else if (String(userGroup) === groupName) {
        console.log("AEP_Admin: ", String(userGroup) === groupName);
        this.setState({
          hideProfile: true, hideAssistance: false, hideReports: false, hideManageUsers: false
        });
      }
      else {
        this.setState({
          hideProfile: false, hideAssistance: false, hideReports: true, hideManageUsers: true
        });
      }
      const myprofilelink = document.querySelector('#myprofilelink');
      const manageuserslink = document.querySelector('#manageuserslink');
      switch (path) {
        case '/reviewregistrationrequests':
        case '/manageagencies':
        case '/manageusers':
          manageuserslink.attributes.class.value = "nav-link dropdown-toggle show";
          break;
        case '/changepassword':
        case '/updateprofile':
          myprofilelink.attributes.class.value = "nav-link dropdown-toggle show";
          break;
        default:
          break;
      }
    }
    // Attach event listener to handle clicks outside of the dropdown menu
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    // Remove event listener when component unmounts
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  initializePasswordUpdate = event => {
    this.props.navigate("/myprofile", {state: { 'passwordUpdated': true }});
  }

  handleLogOut = async () => {
    showSpinner(true);
    try {
      await Auth.signOut({ global: true });
      this.setState({ hideProfile: true, hideManageUsers: true, hideReports: true, hideAssistance: true });
      this.props.auth.setAuthStatus(false);
      this.props.auth.setUser(null);
      this.props.navigate("/login");
    } catch (error) {
      console.log(error.message);
      await Auth.signOut();
      this.setState({ hideProfile: true, hideManageUsers: true, hideReports: true, hideAssistance: true });
      this.props.auth.setAuthStatus(false);
      this.props.auth.setUser(null);
      this.props.navigate("/login");
    }
    showSpinner(false);
  }

  handleAgenciesUsersClick = () => {
    const element = document.querySelector('#manageuserslink');
    if (this.state.manageUsersToggle === false) {
      this.setState({
        manageUsersToggle: true,
        toggleIsOpen: true
      })
    }
    else {
      this.setState({
        manageUsersToggle: false,
        toggleIsOpen: false
      })
    }
    console.log('element: ', element);
    if (element != null) {
      // if caret and dropdown are showing hide ddl onClick
      if (element.attributes.class.value.includes("show") && this.state.toggleIsOpen === true) {
        this.setState({
          toggleIsOpen: false
        });
      }
      // if caret is showing & dll not showing show ddl onClick
      else if (element.attributes.class.value.includes("show") && this.state.toggleIsOpen === false) {
        this.setState({
          toggleIsOpen: true
        });
      }
    }
  }

  // Close the dropdown menu when clicking outside of it
  handleClickOutside = event => {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target)) {
      this.setState({
        manageUsersToggle: false,
        toggleIsOpen: false
      })
    }
  };

  getNavLinkClass = () => {
    const paths = ['/reviewregistrationrequests', '/manageusers', '/manageagencies'];
    return paths.includes(this.props.location.pathname) 
      ? "btn-link nav-link dropdown-toggle show"
      : "btn-link nav-link dropdown-toggle";
  };

  render() {
    return (
      <div>
        <nav class="navbar navbar-light navbar-expand-md aep-navbar agency-navbar" role='navigation'>
          <div class="container">
            <a class="navbar-brand aep-brand" href="#">
              <img src={CorpLogo} class="aep-brand-image" alt="AEP" />
            </a>
            {!this.props.auth.isAuthenticated && this.props.location.pathname !== '/login' &&
              <div>
                <div>
                  <a href="/login" class="aep-login-link-sm mr-0" data-btn="logout">Log In</a>
                </div>
              </div>}
            {this.props.auth.isAuthenticated &&
              (<span id="ctl04_loginButtonMobile">
                <a href="/" onClick={this.handleLogOut} class="aep-login-link-sm" data-btn="logout">
                  Log Out
                </a>
              </span>)}
            {/* TODO: hamburger menu -- needs functionality */}
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav aep-navbar-nav ml-auto">
                <li class="nav-item" hidden={this.state.hideAssistance}>
                  <NavLink className={({ isActive }) => isActive ? 'show nav-link dropdown-toggle' : 'nav-link dropdown-toggle'} to="/AccountSummary" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span>Customer Assistance</span><span class="sr-only">(current)</span></NavLink>
                </li>
                <li class="nav-item" hidden={this.state.hideReports}>
                  <NavLink className={({ isActive }) => isActive ? 'show nav-link dropdown-toggle' : 'nav-link dropdown-toggle'} to="/reports" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span>Reports</span><span class="sr-only">(current)</span></NavLink>
                </li>

                {/* Agencies & Users Dropdown */}
                <li class="nav-item" hidden={this.state.hideManageUsers}>
                  <div ref={this.dropdownRef} className="dropdown" display="static" onClick={this.handleAgenciesUsersClick}>
                    <a className={this.getNavLinkClass()} id="manageuserslink" data-toggle="collapse" aria-expanded="false" aria-controls="managetoggle"><span>Agencies &amp; Users</span></a>
                    <div className={this.state.manageUsersToggle ? 'dropdown-menu show' : 'dropdown-menu'}>
                      <NavLink className="nav-link dropdown-toggle" to="/reviewregistrationrequests" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span>Review Registration Requests</span><span class="sr-only">(current)</span>
                      </NavLink>
                      <NavLink className="nav-link dropdown-toggle" to="/manageusers" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span>Manage Users</span><span class="sr-only">(current)</span>
                      </NavLink>
                      <NavLink className="nav-link dropdown-toggle" to="/manageagencies" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span>Manage Agencies &amp; Locations</span><span class="sr-only">(current)</span>
                      </NavLink>
                    </div>
                  </div>
                </li>

                <li class="nav-item" hidden={this.state.hideProfile}>
                  <NavLink className={({ isActive }) => isActive ? 'show nav-link dropdown-toggle' : 'nav-link dropdown-toggle'} id='myprofilelink' to="/myprofile" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span>My Profile</span><span class="sr-only">(current)</span></NavLink>
                </li>
              </ul>
              {!this.props.auth.isAuthenticated && this.props.location.pathname !== '/login' &&
                <div>
                  <div>
                    <a href="/login" class="btn btn-danger btn-global-login mr-0" data-btn="login">Log In</a>
                  </div>
                </div>}
              {this.props.auth.isAuthenticated &&
                (<span id="ctl04_loginButtonsDesktop">
                  {/* added display classes to show/hide button/link based on screen size */}
                  <a href="/" onClick={this.handleLogOut} class="aep-login-link d-block d-lg-none" data-btn="logout">Log Out</a>
                  <button class="btn btn-danger btn-global-login d-none d-lg-block" onClick={this.handleLogOut} data-btn="login" type="button">LOG OUT</button>
                </span>)}
              <div class="nav-overlay"></div>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}


export default withRouter(Navbar);