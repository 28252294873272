import React from "react";

function FormErrors(props) {
  // if (
  //   props.formerrors &&
  //   (props.formerrors.blankfield || props.formerrors.passwordmatch)
  // ) {
  //   return (
  //     <div className="error container help is-danger">
  //       <div className="row justify-content-center">
  //         {props.formerrors.passwordmatch
  //           ? "Password value does not match confirm password value"
  //           : ""}
  //       </div>
  //       <div className="row justify-content-center help is-danger">
  //         {props.formerrors.blankfield ? "All fields are required" : ""}
  //       </div>
  //     </div>
  //   );
  // } else
  if (props.apierrors) {
    return (
      <div class="help is-danger row mt-10">
        <div class="col">
          <span id="cphContentMain_error" class="login-error invalid-feedback validator error font-weight-bold m-0 mb-2" data-validate-field="cphContentMain_error" >{props.apierrors}</span>
        </div>
      </div>
    );
  } else if (props.formerrors && props.formerrors.cognito && (props.formerrors.cognito.message == "Incorrect username or password." || props.formerrors.cognito.message == "User does not exist.")) {
    return (
      <div class="error">
        <p class="login-error font-weight-bold m-0 mb-2">We're sorry. The email or password entered is invalid. Please try again.</p>
      </div>
    );
  } else if (props.formerrors && props.formerrors.cognito) {
    return (
      <div class="help is-danger row mt-10">
        <div class="col">
          <span id="cphContentMain_error" class="login-error invalid-feedback validator error font-weight-bold m-0 mb-2" data-validate-field="cphContentMain_error" >{props.formerrors.cognito.message}</span>
        </div>
      </div>
    );
  } else if (props.formerrors && props.formerrors.AEPAdmin) {
    return (
      <div class="help is-danger row mt-10">
        <div class="col">
          Reset your password at <a href="https://iforgot.aep.com">https://iforgot.aep.com</a>
        </div>
      </div>
    );
  }
  else {
    return <div />;
  }
}

export default FormErrors;