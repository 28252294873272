import React, { Component } from "react";
import "../Login.css";
import { addHypensPhonenumber } from '../global/formatter';
import config from '../../config.json'
import SuccessMark from '../../lib/images/svgs/ICON_circle-check_green.svg';
import { showSpinner } from './../global/loader';
import { withRouter } from "../withRouter";

const groupName = config.cognito.USER_POOL_ID + '_aep'

class MyProfile extends Component {

  state = {
    hideProfile: false,
    updates: {
      'passwordUpdatedFlag': false,
      'userUpdatedFlag': false,
      'isSuccess': false
    },
    error: {
      cognito: null,
      isError: false,
      password_error: false,
      update_error: false,
      errorMsg: ''
    }
  }

  componentDidMount() {
    showSpinner(false);
    let updates = this.state.updates;
    let error = this.state.error;
    // check to see if user is redirecting from updating info & setState to display correct content
    if (this.props.location.state != undefined && this.props.location.state != null) {
      updates['passwordUpdatedFlag'] = this.props.location.state.passwordUpdated ? true : false;
      updates['userUpdatedFlag'] = this.props.location.state.userUpdated ? true : false;
      updates['isSuccess'] = this.props.location.state.passwordUpdated || this.props.location.state.userUpdated ? true : false;
      error['password_error'] = this.props.location.state.password_error ? true : false;
      error['update_error'] = this.props.location.state.update_error ? true : false;
      this.setState({ updates, error });
    }
    let userGroup = ""
    if (this.props.auth.user != null) {
      userGroup = this.props.auth.user.signInUserSession.accessToken.payload['cognito:groups'];
      if (String(userGroup) === groupName)
        this.props.navigate("/login");
    }
  }

  onEdit = () => {
    this.props.navigate("/updateprofile");
  }

  render() {
    let role = ""

    try {
      if (this.props.auth.user.attributes.nickname != null) {
        role = this.props.auth.user.attributes.nickname.toString()
        role = role.replaceAll("com.aep.agencyextranet.AgencyAdministrator", "Agency Admin")
        role = role.replaceAll("com.aep.agencyextranet.AgencyUser", "Agency User")
      }
    }

    catch (error) {
      console.log("Known bug, first time load, the role does not load properly sometimes. Exception: ")
      console.log(error)
    }

    return (
      <section>
        {/* main content */}
        <div className="container mt-st">
          <div hidden={this.state.hideProfile}>
            <h1>MY PROFILE</h1>
            {this.state.updates.isSuccess &&
              <div class="mb-st display-inline-block">
                <img class="float-left" src={SuccessMark} alt="Success Mark"></img><p class="float-left mt-1 ml-20">Your {this.state.updates.passwordUpdatedFlag ? "password" : "profile information"} has been successfully updated!</p>
              </div>
            }
            {this.state.error.password_error &&
              <div class="row">
                <div class="col-12 error mb-30">
                  <div class="error">
                    <p class="mb-0">We're sorry, something went wrong, please try again. If issue persists please contact <u class="color-dark-blue">agency@aep.com</u> for assistance.</p>
                  </div>
                </div>
              </div>
            }
            <h3 class="clear-both ">Log In Information</h3>

            <div class="row mb-st">
              <div class="col-9">
                <div class="agency-profile-table">
                  <div class="row">
                    <div class="col-12 col-md-4 col-lg-5"><p class="text-bold">Email</p></div>
                    <div class="col-sm-6 col-md-5 col-lg-5">
                      {this.props.auth.user.attributes.email}
                    </div>
                    <div class="col-sm-6 col-md-3 col-lg-2">
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-4 col-lg-5"><p class="text-bold">Password</p></div>
                    <div class="col-sm-6 col-md-5 col-lg-5">
                      ********
                    </div>
                    <div class="col-sm-6 col-md-3 col-lg-2">
                      <a href="/changepassword">Change</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h3>Your Information</h3>
            <div class="row">
              <div class="col-9">
                <div class="agency-profile-table">
                  <div class="row">
                    <div class="col-12 col-md-4 col-lg-5"><p class="text-bold">Your Name</p></div>
                    <div class="col-sm-6 col-md-5 col-lg-5">
                      {this.props.auth.user.attributes.given_name} {this.props.auth.user.attributes.family_name}
                    </div>
                    <div class="col-sm-6 col-md-3 col-lg-2">
                      <a href="/updateprofile" onClick={this.onEdit}>Change</a>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-4 col-lg-5"><p class="text-bold">Phone Number</p></div>
                    <div class="col-sm-6 col-md-5 col-lg-5">
                      {addHypensPhonenumber(this.props.auth.user.attributes.phone_number)}
                    </div>
                    <div class="col-sm-6 col-md-3 col-lg-2">
                      <a href="/updateprofile" onClick={this.onEdit}>Change</a>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-4 col-lg-5"><p class="text-bold">Role</p></div>
                    <div class="col-sm-6 col-md-6 col-lg-5">
                      {role}
                    </div>
                    <div class="col-sm-6 col-lg-2"></div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-4 col-lg-5"><p class="text-bold">Agency</p></div>
                    <div class="col-sm-6 col-md-6 col-lg-5">
                      {this.props.auth.user.attributes.profile}
                    </div>
                    <div class="col-sm-6 col-lg-2"></div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-4 col-lg-5"><p class="text-bold">Agency Location(s)</p></div>
                    <div class="col-sm-6 col-md-6 col-lg-5">
                      {this.props.auth.user.attributes.address}
                    </div>
                    <div class="col-sm-6 col-lg-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default withRouter(MyProfile);
