import React, { Component } from "react";
import Bill_Logo from '../../lib/images/svgs/ICON_bill.svg';
import Calendar_Logo from '../../lib/images/svgs/ICON_calendar.svg';
import { addHypensPhonenumber, addHypensAccountNum, translateDate, numberWithCommas } from '../global/formatter';
import DatePicker from "react-datepicker";
import {startDateOHWinterSeasonAppointment, endDateOHWinterSeason} from '../../config';
import "react-datepicker/dist/react-datepicker.css";
import { addDays , subDays, differenceInDays} from 'date-fns';
import moment from "moment";
import { showSpinner } from './../global/loader';
import { Auth } from 'aws-amplify';
import { withRouter } from "../withRouter";


class ScheduleAppointment extends Component {
    state = {
        noDataFlag: true,
        isDisabledFlag: true,
        isDisabledCalendarFlag: true,
        selectedDate: '',
        isErrorSchedule: '',
        setMinDate: new Date(),
        setMaxDate: new Date(),
        programs: [],
        appointmentTypes: []
    }

   

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.location.state !== undefined) {
            this.setState({ noDataFlag: false, originalState: this.props.location.state.originalState,selectedDate: '' });
            let result_min = '';
            if (this.props.location.state.fromAccSumm)
                this.setState({ isDisabledFlag: true, selectedDate: this.props.location.state.selectedDate, appointmentTypes: this.props.location.state.appointmentTypes, originalState: this.props.location.state.originalState, minDateValue: result_min });
        }
    }

    appointmentInfoSearch(appointmentType){
        for(var i = 0; i < this.state.appointmentTypes.length; i++)
            {
            if(this.state.appointmentTypes[i].name == appointmentType)
            {
                console.log("found in for loop")
                console.log(appointmentType)
                return {"start_date": this.state.appointmentTypes[i].start_date,"end_date": this.state.appointmentTypes[i].end_date}
            }
            }
        console.log("did not find in for loop")
        return {"start_date": new Date()  , "end_date": new Date() }
        }

    handleAppointment = (event, key, program_id) => {
        console.log(event);
        console.log("appointment select value")
        if (typeof event == 'undefined'){
            this.setState({ appointment: event, isDisabledFlag: true,isDisabledCalendarFlag: true,selectedDate: '',setMinDate: new Date(), setMaxDate:new Date(), isErrorSchedule: 'Please select an appointment' });
        }
        else if (event == 'null' || event == '')
        {
            this.setState({ appointment: event, isDisabledFlag: true,isDisabledCalendarFlag: true,selectedDate: '',setMinDate: new Date(), setMaxDate:new Date(), isErrorSchedule: 'Please select an appointment' });
        }
        else
        {
            var dateRange = this.appointmentInfoSearch(event)
            console.log(dateRange)
            let currentdate = new Date();
            let startdate = new Date(dateRange['start_date']);
            let enddate = new Date(dateRange['end_date']);
            if(currentdate >= startdate && currentdate <= enddate){
                const enddatediff = differenceInDays(enddate,currentdate );
                console.log(enddatediff)
                if(enddatediff >28){
                    this.setState({ appointment: event, appointmentID: program_id, isDisabledCalendarFlag: false, isErrorSchedule: '',setMinDate: addDays(currentdate, 1), setMaxDate:  addDays(currentdate, 28) });
                }
                else{
                    this.setState({ appointment: event, appointmentID: program_id, isDisabledCalendarFlag: false, isErrorSchedule: '',setMinDate: addDays(currentdate, 1), setMaxDate:  addDays(currentdate, enddatediff+1) });
                }
                            }
            else if(currentdate >= startdate && currentdate > enddate){
                this.setState({ appointment: event, isDisabledFlag: true,isDisabledCalendarFlag: true,selectedDate: '', isErrorSchedule: 'We are sorry, something went wrong, please try again. If issue persists please contact agency@aep.com for assistance.' })

            }
            else if (currentdate < startdate) {
                this.setState({ appointment: event, appointmentID: program_id, isDisabledCalendarFlag: false, isErrorSchedule: '', setMinDate: startdate,setMaxDate: addDays(currentdate, 27) });
            }
            else{
                this.setState({ appointment: event, isDisabledFlag: true,isDisabledCalendarFlag: true,selectedDate: '', isErrorSchedule: 'We are sorry, something went wrong, please try again. If issue persists please contact agency@aep.com for assistance.' })
            }
        }
        }
    
        handleDateChange = (value) => {
        console.log(value);
        if (value == null)
            this.setState({ selectedDate: value, isDisabledFlag: true, isErrorSchedule: 'Please enter a valid date' });
        else
            this.setState({ selectedDate: value, isDisabledFlag: false, isErrorSchedule: '' });
    }

    handleLogOut = async () => {
        showSpinner(true);
        try {
          await Auth.signOut({ global: true });
          this.setState({ hideProfile: true, hideManageUsers: true, hideReports: true, hideAssistance: true });
          this.props.auth.setAuthStatus(false);
          this.props.auth.setUser(null);
          this.props.navigate("/login");
        } catch (error) {
          console.log(error.message);
          await Auth.signOut();
          this.setState({ hideProfile: true, hideManageUsers: true, hideReports: true, hideAssistance: true });
          this.props.auth.setAuthStatus(false);
          this.props.auth.setUser(null);
          this.props.navigate("/login");
        }
        showSpinner(false);
      }

    changeCustomer = () => {
        try{
            this.props.navigate('/accountsummary');
        } catch(err) {
            console.log(err);
            this.handleLogOut();
        }
    };

    openDatepicker = () => this._calendar.setOpen(true);

    submitFunction = event => {
        event.preventDefault();
        if (!this.state.noDataFlag) {
            try{
            this.props.navigate('/reviewappointment', {state: {
                'selectedDate': this.state.selectedDate,
                'addresssummaryresp': this.props.location.state.addresssummaryresp,
                'addresslookupresp': this.props.location.state.addresslookupresp,
                'selectedAddress': this.props.location.state.selectedAddress,
                'appointment': this.state.appointment,
                'appointmentID': this.state.appointmentID,
                'originalState': this.props.location.state.originalState
            }});
        }catch(err) {
            console.log(err);
            this.handleLogOut();
        }

        }
    }


      filterDays = (date) => {
        // Disable Weekends
        if (date.getDay() === 0 || date.getDay() === 6) {
            return false;
        } else {
            return true;
        }
    }
    cancelAppointment = event => {
      try{
          this.props.navigate('/accountsummary', {state: this.state.originalState});
          }catch(err) {
          console.log(err);
          this.handleLogOut();
      }
    }

    render() {
        return (
            <section class="section">
                <div id="main" class="container">
                    <span id="cphContentMain_GlobalUserControl1">
                        <h1>Schedule Appointment</h1>
                        <div id="cphContentMain_ScheduleAppointmentDiv">
                            {(!this.state.noDataFlag && this.props.location.state.addresssummaryresp !== undefined) &&
                                (<h2> {this.props.location.state.addresssummaryresp["CustomerData"].cust_1st_nm} {this.props.location.state.addresslookupresp.CustMiddlenm} {this.props.location.state.addresssummaryresp["CustomerData"].cust_last_nm} {this.props.location.state.addresslookupresp.CustSuffixnm}
                                </h2>)}
                            <div class="row mb-st">
                                <div class="col-12 col-sm-11 col-lg-4">
                                    <p class="text-bolder mb-10">Service Location:</p>
                                    {!this.state.noDataFlag && (
                                        <p class="mb-0 text-uppercase">
                                            {this.props.location.state.selectedAddress.HouseNumber} {this.props.location.state.selectedAddress.StreetName} {this.props.location.state.selectedAddress.StreetDsgt} {this.props.location.state.selectedAddress.UnitDesignator} {this.props.location.state.selectedAddress.UnitNumber}<br />
                                            {this.props.location.state.selectedAddress.City} {this.props.location.state.selectedAddress.State} {this.props.location.state.selectedAddress.ZipCode}
                                        </p>)}
                                </div>
                                <div class="col-12 col-sm-11 col-lg-4 mb-30 mb-md-40 mb-lg-0">
                                    <p class="text-bolder float-left mb-10 width-45">Account Number:</p>
                                    {!this.state.noDataFlag && (<p class="float-left width-55">{addHypensAccountNum(this.props.location.state.selectedAddress.AccountNum) + '-' + this.props.location.state.selectedAddress.BillCheckDigitNb}</p>)}
                                    <p class="text-bolder float-left clear-both width-45 mb-10">Phone Number:</p>
                                    {!this.state.noDataFlag && (<p class="float-left width-55">{this.props.location.state.selectedAddress && (addHypensPhonenumber(this.props.location.state.addresssummaryresp["CustomerData"].tlph_nb))}</p>)}
                                </div>
                                <div class="col-12 col-sm-11 col-lg-4 text-align-right">
                                    <button class="btn btn-secondary mb-20" onClick={this.changeCustomer}>Change Customer</button>
                                </div>
                            </div>
                            <hr class="divider-sm mb-st" />
                            <div class="col-sm-10 col-md-10 col-xl-10 no-margins">
                                <span id="cphContentMain_ctl00_GlobalUserControl1">
                                    <div id="cphContentMain_ctl00_ctl00_PnlPledgeForm">
                                        <div id="cphContentMain_ctl00_ctl00_pledgeForm" role="form" class="bordered-form border-bottom-none heading-form">
                                            <div id="cphContentMain_ctl00_ctl00_DivHeading" class="row">
                                                <div class="col-12 col-lg-4">
                                                    <img src={Bill_Logo} class="float-left" alt="Bill Summary" style={{ width: '48px' }} />
                                                    <h3 class="float-left ml-20 mt-20">Bill Summary</h3>
                                                </div>
                                                <div class="col-12 col-sm-11 col-lg-3 mb-30 mb-md-40 mb-lg-0">
                                                    <p class="text-bold mt-10 clear-both mb-10" style={{ 'lineHeight': '25px' }}><span>Amount Due: </span></p>
                                                    {!this.state.noDataFlag && (<p class="mt-10 text-bold">$ {numberWithCommas(Number(this.props.location.state.addresssummaryresp["AmountsDue"].bal_due_at).toFixed(2))}</p>)}
                                                </div>
                                                <div class="col-12 col-sm-11 col-lg-3 mb-30 mb-md-40 mb-lg-0">
                                                    <p class="text-bold mt-10 mb-10" style={{ 'lineHeight': '25px' }}><span>Due Date: </span></p>
                                                    {!this.state.noDataFlag && (<p class="mt-10 text-bold">{translateDate(this.props.location.state.addresssummaryresp["AmountsDue"].bill_due_dt)}</p>)}
                                                </div>
                                            </div>
                                        </div>
                                        <div role="form" class="bordered-form">
                                            <h3 class="mb-20">Schedule a credit extension to prevent disconnection.</h3>
                                            <p class="mb-15">Select an appointment date. One appointment allowed for each type.</p>
                                            <div class="form-row mb-20">
                                                <div class="col-12 col-md-9">
                                                    <div id="agency_select_wrapper" class="form-group aep-custom moveable-label">
                                                        <div class="input-group">           
                                                        <select name="ctl00$cphContentMain$selectProgram$cphContentMain_selectProgram" class="form-control custom-select has-value" data-field-type="dropdown" onChange={(event) => this.handleAppointment(event.target.value, event.target.name, event.target.options[event.target.selectedIndex].getAttribute("data-program-id"))} required={true} data-required="true" data-required-message="Please select an appointment">
                                                            <option value='null' class="blank">None</option>
                                                            {this.state.appointmentTypes && this.state.appointmentTypes.map((programs) => (
                                                                            <option key={programs.program_id} value={programs.name}
                                                                                data-program-id={programs.program_id}>
                                                                                {programs.name}
                                                                            </option>
                                                            ))}
                                                        </select>
                                                        <label htmlFor={'cphContentMain_selectProgram'}>Select an appointment</label>    
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="wrapper-block">
                                                <div class="form-row mb-20">
                                                    <div class="col-12 col-md-6">
                                                        <div id="datepicker-wrapper" class="form-group moveable-label aep-custom">
                                                            <div class="input-group">
                                                            {/* this.state.minDateValue  */}
                                                                <DatePicker selected={this.state.selectedDate} onChange={date => this.handleDateChange(date)} minDate={this.state.setMinDate} maxDate={this.state.setMaxDate} filterDate={this.filterDays} disabled={this.state.noDataFlag || this.state.isDisabledCalendarFlag} class="form-control" ref={(c) => this._calendar = c} />
                                                                <img src={Calendar_Logo} class="float-left mb-10 calendar-icon cursor-pointer" alt="Calendar Datepicker" onClick={this.openDatepicker} />
                                                            </div>
                                                            {this.state.isErrorSchedule !== '' && (<span id="cphContentMain_selectedDate" class="invalid-feedback validator font-weight-bold error m-0 mb-2" data-validate-field="cphContentMain_selectedDate">{this.state.isErrorSchedule}</span>)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-10">
                                                <div class="col">
                                                    <button class="btn btn-primary mr-20" onClick={this.submitFunction} disabled={this.state.noDataFlag || this.state.isDisabledFlag}>Review Appointment</button>
                                                </div>
                                            </div>
                                            <div class="row mb-10">
                                                <div class="col button">
                                                    <button class="btn button-without-border" onClick={this.cancelAppointment} style={{
                                                        fontSize: '17px', padding: '0 0 17px',
                                                        textTransform: 'none', lineHeight: '2.3077', borderWidth: 0
                                                    }}>Cancel Appointment</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </span>
                </div>
            </section >
        )
    }
}


export default withRouter(ScheduleAppointment);