import React, { Component } from "react";
import Bill_Logo from '../../lib/images/svgs/ICON_bill.svg';
import Add_Logo from '../../lib/images/svgs/ICON_plus_blue.svg';
import Remove_Logo from '../../lib/images/svgs/ICON_x_blue.svg';
import { Auth } from 'aws-amplify';
import { addHypensPhonenumber, addHypensAccountNum, translateDate, numberWithCommas, dateWithSlashes,checkIfBetweenTwoDates } from '../global/formatter';
import * as MakeAPledgeClass from '../constants/makeaPledgeConstants';
import { NumericFormat } from 'react-number-format';
import moment from 'moment';
import config from './../../config'
import { showSpinner } from './../global/loader';
import { withRouter } from "../withRouter";

let globalId = 0;
let selectID = 0;
const sampleHeaders = {
    'Content-Type': 'application/json',
    'x-api-key': config.accountSearchShadowService.token
};
const groupName = config.cognito.USER_POOL_ID + '_aep'

//Number formatting for textbox taken from https://github.com/s-yadav/react-number-format : 
//Includes many more formatting for textboxes like credit card seperation, phone number dashes, seperations with +1 code and more, masking, etc. 
class MakeAPledge extends Component {

    pledge_amt = React.createRef();
    dtlcasedocid = React.createRef();
    transactionID = React.createRef();
    selectProgram = React.createRef();

    state = {
        btnDisabledFlag: false,
        noDataFlag: true,
        totalPledgeAmount: 0.00,
        isDisabledFlag: true,
        response: { programs: [] },
        agency_details: {},
        isAccountDisconnected: false,
        disconnectAmt: '',
        disconnectDate: '',
        pledgeList: [{
            id: globalId++,
            selectProgram: "",
            setErrorSelectProgram: true,
            setErrorPledgeAmount: true,
            touchAmount: false,
            touchSelect: false,
            flagRemove: false,
            transactionID: "",
            asstpgmcode: "",
            apmn_fl: "",
            apply_credit_fl: "",
            srdr_fl: "",
            mfcc_fl: "",
            pace_fl: "",
            pledge_amt: "",
            auth_remarks: "",
            dtlcasedocid: "",
            statecode: "",
            remarkstxt: "",
            macss_code: ""
        }],
        amtListValues: [{ amt: 0 }]
    }

    handleLogOut = async () => {
        showSpinner(true);
        try {
          await Auth.signOut({ global: true });
          this.setState({ hideProfile: true, hideManageUsers: true, hideReports: true, hideAssistance: true });
          this.props.auth.setAuthStatus(false);
          this.props.auth.setUser(null);
          this.props.navigate("/login");
        } catch (error) {
          console.log(error.message);
          await Auth.signOut();
          this.setState({ hideProfile: true, hideManageUsers: true, hideReports: true, hideAssistance: true });
          this.props.auth.setAuthStatus(false);
          this.props.auth.setUser(null);
          this.props.navigate("/login");
        }
        showSpinner(false);
      }

    callProgram = async () => {
        if (this.props.location.state === undefined) {
            this.setState({
                response: {
                    programs: await this.search_programs_in_es('', 'A')
                }
            }, function () {
                console.log("state is undefined response", this.state.response.programs)
            });
        }
        else {
            this.setState({
                response: {
                    programs: await this.search_programs_in_es(this.props.location.state.selectedAddress.State, 'A')
                }
            }, function () {
                console.log("state is defined response", this.state.response.programs);
            });
        }
    }

    search_programs_in_es = async (state, status) => {
        const shadowservicegenericURL = new URL(config.shadowServiceGenericURL.url + "searchPrograms");
        const params = { "state": state, "status": status };
        Object.keys(params).forEach(key => shadowservicegenericURL.searchParams.append(key, params[key]));
        try {
            return new Promise((resolve, reject) => {
                fetch(shadowservicegenericURL, {
                    method: 'POST',
                    headers: sampleHeaders
                })
                    .then(res => res.json())
                    .then(
                        (data) => {
                            console.log(data);
                            let programs = [];
                            if (data['hits']) {
                                let autocomplete_array = data['hits']['hits']
                                autocomplete_array.forEach(function (option) {
                                    console.log(option);
                                    let obj = {};
                                    obj["program_id"] = option['_source']['program_id'];
                                    obj["name"] = option['_source']['name'];
                                    obj["status"] = option['_source']['status'];
                                    obj["start_date"] = option['_source']['start_date'];
                                    obj["end_date"] = option['_source']['end_date'];
                                    obj["last_update_user"] = option['_source']['last_update_user'];
                                    obj["last_update_timestamp"] = option['_source']['last_update_timestamp'];
                                    obj["macss_code"] = option['_source']['macss_code'];
                                    obj["created_date"] = option['_source']['created_date'];
                                    obj["created_by"] = option['_source']['created_by'];
                                    obj["apply_credit_fl"] = option['_source']['apply_credit_fl'];
                                    obj["srdr_fl"] = option['_source']['srdr_fl'];
                                    obj["mfcc_fl"] = option['_source']['mfcc_fl'];
                                    obj["pace_fl"] = option['_source']['pace_fl'];
                                    obj["apmn_fl"] = option['_source']['apmn_fl'];
                                    obj["state"] = option['_source']['state'];
                                    console.log(obj);
                                    programs.push(obj);
                                });
                                console.log(programs);
                            }
                            showSpinner(false);
                            resolve(programs);
                        },
                        (error) => {
                            console.log(error)
                            showSpinner(false);
                            reject([]);
                        }
                    );
            });
        }
        catch (err) {
            console.log(err);
            showSpinner(false);
            return [];
        }
    }

    callAgencyInformation = () => {
        let agency_det = {};
        console.log(this.props);
        console.log(this.props.auth.user["attributes"])
        agency_det["name"] = this.props.auth.user["attributes"].name;
        agency_det["city"] = this.props.auth.user["attributes"].picture;
        this.setState({
            agency_details: agency_det
        });
    }

    async componentDidMount() {
        
        window.scrollTo(0, 0);
        showSpinner(true);
        await Auth.currentAuthenticatedUser();
        console.log(JSON.stringify(this.props.location.state) + "state here::::");
        if (this.props.location.state !== undefined) {
            this.setState({ noDataFlag: false, isDisabledFlag: true, originalState: this.props.location.state.originalState });
            if (this.props.location.state.fromAccSumm)
                this.callProgram();
            else if (!this.props.location.state.fromAccSumm) {
                this.setState({ noDataFlag: false, isDisabledFlag: false });
                if (this.props.location.state.pledgeList !== undefined && this.props.location.state.pledgeList !== null) {
                    this.setState({ pledgeList: [], totalPledgeAmount: 0 }, function () {
                        this.setState({ pledgeList: this.props.location.state.pledgeList, totalPledgeAmount: this.props.location.state.totalPledgeAmount });
                    });
                    this.state.amtListValues.pop();
                    Object.entries(this.props.location.state.pledgeList).map(([key, pledgeInfo]) => {
                        this.state.amtListValues.push({ amt: pledgeInfo.pledge_amt });
                        return pledgeInfo.pledge_amt;
                    });
                    this.setState({
                        response: {
                            programs: this.props.location.state.programs
                        }
                    });
                }
                showSpinner(false);
            }
        }
        else {
            this.setState({ noDataFlag: true, isDisabledFlag: true });
            this.callProgram();
        }
        let userGroup = ""
        if (this.props.auth.user != null) {
            userGroup = this.props.auth.user.signInUserSession.accessToken.payload['cognito:groups'];
            if (String(userGroup) === groupName)
                this.callAgencyAEPAdmin();
            else
                this.callAgencyInformation();             
            this.setDisconnectValues();
        }
    }

    callAgencyAEPAdmin = () => {
        let agency_det = {};
        agency_det["name"] = "ALL";
        agency_det["city"] = "ALL";
        this.setState({
            agency_details: agency_det
        });
    }

    setDisconnectValues = () => {
        const acct_stat_cd = this.props.location.state.addresssummaryresp.CustomerData.acct_stat_cd;
        const discdate1 = this.props.location.state.selectedAddress.Disconnectdate_1;
        const discamt1 = this.props.location.state.selectedAddress.Disconnectamount_1;
        const discdate2 = this.props.location.state.selectedAddress.Disconnectdate_2;
        const discamt2 = this.props.location.state.selectedAddress.Disconnectamount_2;
        if (acct_stat_cd === 'A' || acct_stat_cd === 'P' || acct_stat_cd === 'N') {
            this.setState({ isAccountDisconnected: true }, () => {
                console.log(discamt1);
                console.log(discdate1);
                if (discamt1 > 0 && discamt1 !== "" && discamt1 !== undefined)
                    this.setState({ disconnectAmt: numberWithCommas(discamt1), disconnectDate: dateWithSlashes(discdate1) });
                else if (discamt2 > 0 && discamt2 !== "" && discamt2 !== undefined)
                    this.setState({ disconnectAmt: numberWithCommas(discamt2), disconnectDate: dateWithSlashes(discdate2) });
                else
                    this.setState({ isAccountDisconnected: false });
            });
        }
    }

    setDisabledFlag = () => {
        let flag = false;
        for (let i = 0; i < this.state.pledgeList.length; i++) {
            if (this.state.pledgeList[i].setErrorPledgeAmount || this.state.pledgeList[i].setErrorSelectProgram) {
                flag = true;
                break;
            }
        }
        if (flag)
            this.setState({ isDisabledFlag: true });
        else
            this.setState({ isDisabledFlag: false });
    }

    calculateRemarkText = (transactionID) => {
        console.log(JSON.stringify(this.state.agency_details) + ":::" + JSON.stringify(this.state.agency_details) + "::" + transactionID);
        return this.state.agency_details.name + "-" + moment().format("MMDDYYYY") + "-" + transactionID;
    }

    calculateAuthRemarks = () => {
        console.log(JSON.stringify(this.state.agency_details) + ":::" + JSON.stringify(this.state.agency_details) + "::" + this.state.agency_details.name);
        return this.state.agency_details.name + "-" + moment().format("MMDDYYYY") + "-" + this.state.agency_details.city;
    }

    setMACCSFlagValues = (element, name) => {
        const INwinterSeason = checkIfBetweenTwoDates(moment().format("MM/DD/YYYY"), config.startDateINwintermoratorium, config.endDateINwintermoratorium);
        const index = this.state.response.programs.findIndex(obj => obj.name === name);
        if (index !== -1 && name !== null && name !== '') {
            element.macss_code = this.state.response.programs[index]["macss_code"];
            element.asstpgmcode = this.state.response.programs[index]["program_id"];
            element.apply_credit_fl = this.state.response.programs[index]["apply_credit_fl"];
            element.statecode = this.state.response.programs[index]["state"];
            element.srdr_fl = this.state.response.programs[index]["srdr_fl"];
            element.pace_fl = this.state.response.programs[index]["pace_fl"];
            element.apmn_fl = this.state.response.programs[index]["apmn_fl"];            
            element.mfcc_fl = !(element.statecode === "IN" && INwinterSeason ) ?  element.mfcc_fl = "N" : this.state.response.programs[index]["mfcc_fl"];
            element.auth_remarks = this.calculateAuthRemarks();

        }
        else {
            element.macss_code = '';
            element.asstpgmcode = '';
            element.apply_credit_fl = '';
            element.srdr_fl = '';
            element.mfcc_fl = '';
            element.pace_fl = '';
            element.apmn_fl = '';
            element.statecode = '';
            element.remarkstxt = '';
            element.auth_remarks = '';
        }
        return element;
    }

    handleChange = (value, key, name) => {
        if (name.includes("select")) {
            if (!this.state.pledgeList[key].touchSelect) {
                const pledgeListElement = this.state.pledgeList.slice();
                pledgeListElement[key].touchSelect = true;
                this.setState({ pledgeListElement });
            }

            if (value === '' || value === null) {
                const pledgeListElement = this.state.pledgeList.slice();
                pledgeListElement[key].setErrorSelectProgram = true;
                pledgeListElement[key].selectProgram = value;
                pledgeListElement[key] = this.setMACCSFlagValues(pledgeListElement[key], value);
                this.setState({ pledgeListElement });
            }
            else {
                let pledgeListElement = this.state.pledgeList.slice();
                pledgeListElement[key].setErrorSelectProgram = false;
                pledgeListElement[key].selectProgram = value;
                pledgeListElement[key] = this.setMACCSFlagValues(pledgeListElement[key], value);
                pledgeListElement[key].remarkstxt = this.calculateRemarkText(pledgeListElement[key].transactionID);
                this.setState({ pledgeListElement });
            }
            this.setDisabledFlag();
        }
        else if (name.includes("transaction")) {
            const pledgeListElement = this.state.pledgeList.slice();
            pledgeListElement[key].transactionID = value;
            pledgeListElement[key].remarkstxt = this.calculateRemarkText(value);
            this.setState({ pledgeListElement });
        }
        else {
            const pledgeListElement = this.state.pledgeList.slice();
            pledgeListElement[key].dtlcasedocid = value;
            this.setState({ pledgeListElement });
        }
    };

    handleChangeAmount = (values, key) => {
        let { value } = values;
        value = Number(value).toFixed(2);
        if (!this.state.pledgeList[key].touchAmount) {
            const pledgeListElement = this.state.pledgeList.slice();
            pledgeListElement[key].touchAmount = true;
            this.setState({ pledgeListElement });
        }

        if (value < 1 || value > 1500) {
            const pledgeListElement = this.state.pledgeList.slice();
            pledgeListElement[key].setErrorPledgeAmount = true;
            pledgeListElement[key].pledge_amt = value;
            this.setState({ pledgeListElement });
        }
        else {
            const pledgeListElement = this.state.pledgeList.slice();
            pledgeListElement[key].setErrorPledgeAmount = false;
            pledgeListElement[key].pledge_amt = value;
            this.setState({ pledgeListElement });
        }

        let items = [...this.state.amtListValues];
        items[key].amt = value;
        this.setState({ items });
        let temp = Number(this.state.amtListValues.reduce((totalSum, value) => Number(totalSum) + Number(value.amt), 0)).toFixed(2);
        this.setState({ totalPledgeAmount: temp });
        this.setDisabledFlag();
    }

    changeCustomer = () => {
        try{
        this.props.navigate('/accountsummary');
        }catch(err) {
            console.log(err);
            this.handleLogOut();
        }
    };

    addPledge = () => {
        this.state.pledgeList.push({
            id: globalId++,
            selectProgram: "",
            setErrorSelectProgram: true,
            setErrorPledgeAmount: true,
            touchAmount: false,
            touchSelect: false,
            flagRemove: true,
            transactionID: "",
            asstpgmcode: "",
            apmn_fl: "",
            apply_credit_fl: "",
            srdr_fl: "",
            mfcc_fl: "",
            pace_fl: "",
            pledge_amt: "",
            auth_remarks: "",
            dtlcasedocid: "",
            statecode: "",
            remarkstxt: "",
            macss_code: ""
        });
        this.state.amtListValues.push({ amt: 0 });
        this.setState({ pledgeList: this.state.pledgeList, amtListValues: this.state.amtListValues });
        if (this.state.pledgeList.length === 4)
            this.setState({ btnDisabledFlag: true });
        this.setDisabledFlag();
    }

    cancelPledge = () => {
        // console.log("Cancel pledge")
        // console.log(this.props.location.state.originalState)
        try{
        this.props.navigate('/accountsummary', {state: this.state.originalState});
        }catch(err) {
            console.log(err);
            this.handleLogOut();
        }
    }

    removePledge = (key, id) => {
        if (this.state.pledgeList.length === 4)
            this.setState({ btnDisabledFlag: false });
        const pledgeListElement = this.state.pledgeList.slice();
        pledgeListElement[key].setErrorPledgeAmount = false;
        pledgeListElement[key].setErrorSelectProgram = false;
        this.setState({ pledgeListElement });
        this.state.amtListValues.splice(key, 1);
        const newElements = this.state.pledgeList.filter((element) => Number(element.id) !== Number(id));
        this.setState({
            pledgeList: [], amtListValues: this.state.amtListValues
        },
            () => {
                this.setState({ pledgeList: newElements });
            });
        this.setState({ totalPledgeAmount: this.state.amtListValues.reduce((totalSum, value) => Number(totalSum) + Number(value.amt), 0) });        
        if (Number(this.state.pledgeList.length) >= 2)
        {
        if (Number(this.state.pledgeList[(this.state.pledgeList.length-1)-1].pledge_amt)  > 0)
        this.setState({ isDisabledFlag: false });
        else 
        this.setState({ isDisabledFlag: true });
        }
        else 
        this.setState({ isDisabledFlag: true });
    }

    submitFunction = event => {
        event.preventDefault();
        try{
        if (!this.state.noDataFlag) {
            this.props.navigate('/reviewpledge', {state: {
                'pledgeList': this.state.pledgeList,
                'totalPledgeAmount': this.state.totalPledgeAmount,
                'addresssummaryresp': this.props.location.state.addresssummaryresp,
                'addresslookupresp': this.props.location.state.addresslookupresp,
                'selectedAddress': this.props.location.state.selectedAddress,
                'programs': this.state.response.programs,
                'originalState': this.state.originalState
            }});
        }}catch(err) {
            console.log(err);
            this.handleLogOut();
        }
    }

    render() {
        return (

            <section className="section">
                <div id="main" class="container">
                    <span id="cphContentMain_GlobalUserControl1">
                        <h1>Make a Pledge</h1>
                        <div id="cphContentMain_ctl00_NotTexasDiv">
                            {(!this.state.noDataFlag && this.props.location.state.addresssummaryresp !== undefined) &&
                                (<h2> {this.props.location.state.addresssummaryresp["CustomerData"].cust_1st_nm} {this.props.location.state.addresslookupresp.CustMiddlenm} {this.props.location.state.addresssummaryresp["CustomerData"].cust_last_nm} {this.props.location.state.addresslookupresp.CustSuffixnm}
                                </h2>)}
                            <div class="row mb-st">
                                <div class="col-12 col-sm-11 col-lg-4">
                                    <p class="text-bolder mb-10">Service Location:</p>
                                    {!this.state.noDataFlag && (
                                        <p class="mb-0 text-uppercase">
                                            {this.props.location.state.selectedAddress.HouseNumber} {this.props.location.state.selectedAddress.StreetName} {this.props.location.state.selectedAddress.StreetDsgt} {this.props.location.state.selectedAddress.UnitDesignator} {this.props.location.state.selectedAddress.UnitNumber}<br />
                                            {this.props.location.state.selectedAddress.City} {this.props.location.state.selectedAddress.State} {this.props.location.state.selectedAddress.ZipCode}
                                        </p>)}
                                </div>

                                <div class="col-12 col-sm-11 col-lg-4 mb-30 mb-md-40 mb-lg-0">
                                    <p class="text-bolder float-left mb-10 width-45">Account Number:</p>
                                    {!this.state.noDataFlag && (<p class="float-left width-55">{addHypensAccountNum(this.props.location.state.selectedAddress.AccountNum) + '-' + this.props.location.state.selectedAddress.BillCheckDigitNb}</p>)}
                                    <p class="text-bolder float-left clear-both width-45 mb-10">Phone Number:</p>
                                    {!this.state.noDataFlag && (<p class="float-left width-55">{this.props.location.state.selectedAddress && (addHypensPhonenumber(this.props.location.state.addresssummaryresp["CustomerData"].tlph_nb))}</p>)}
                                </div>

                                <div class="col-12 col-sm-11 col-lg-4 text-align-right">
                                    <button class="btn btn-secondary mb-20" onClick={this.changeCustomer}>Change Customer</button>
                                </div>
                            </div>
                            <hr class="divider-sm mb-st" />


                            <div class="col-sm-10 col-md-10 col-xl-10 no-margins">
                                <span id="cphContentMain_ctl00_GlobalUserControl1">
                                    <div id="cphContentMain_ctl00_ctl00_PnlPledgeForm">
                                        <div id="cphContentMain_ctl00_ctl00_pledgeForm" role="form" class="bordered-form border-bottom-none heading-form">
                                            <div id="cphContentMain_ctl00_ctl00_DivHeading" class="row">
                                                <div class="col-12 col-lg-4">
                                                    <img src={Bill_Logo} class="float-left" alt="Bill Summary" style={{ width: '48px' }} />
                                                    <h3 class="float-left ml-20 mt-20">Bill Summary</h3>
                                                </div>
                                                <div class="col-12 col-sm-11 col-lg-4 mb-30 mb-md-40 mb-lg-0">
                                                    {(!this.state.noDataFlag && this.state.isAccountDisconnected) && (<span>
                                                        <p class="text-bold float-left mb-10 width-60" style={{ 'lineHeight': '25px' }}><span>Avoid Disconnect: </span></p>
                                                        <p class="float-left width-40 text-bold">$ {numberWithCommas(Number(this.state.disconnectAmt).toFixed(2))}</p>
                                                    </span>)}
                                                    <p class="text-bold float-left clear-both width-60 mb-10" style={{ 'lineHeight': '25px' }}><span>Amount Due: </span></p>
                                                    {!this.state.noDataFlag && (<p class="float-left width-40 text-bold">$ {numberWithCommas(Number(this.props.location.state.addresssummaryresp["AmountsDue"].bal_due_at).toFixed(2))}</p>)}
                                                </div>
                                                <div class="col-12 col-sm-11 col-lg-4 mb-30 mb-md-40 mb-lg-0">
                                                    {(!this.state.noDataFlag && this.state.isAccountDisconnected) && (<span>
                                                        <p class="text-bold float-left mb-10 width-55" style={{ 'lineHeight': '25px' }}><span>Disconnect Date: </span></p>
                                                        <p class="float-left width-45 text-bold">{translateDate(this.state.disconnectDate)}</p>
                                                    </span>)}
                                                    <p class="text-bold float-left clear-both width-55 mb-10" style={{ 'lineHeight': '25px' }}><span>Due Date: </span></p>
                                                    {!this.state.noDataFlag && (<p class="float-left width-45 text-bold">{translateDate(this.props.location.state.addresssummaryresp["AmountsDue"].bill_due_dt)}</p>)}
                                                </div>
                                            </div>

                                        </div>
                                        <div role="form" class="bordered-form">
                                            <h3 class="mb-40">Enter  up  to  4  pledges  below.</h3>

                                            {Object.entries(this.state.pledgeList).map(([key, pledgeInfo]) => (

                                                <div class="wrapper-block" key={key}>

                                                    {pledgeInfo.flagRemove && (<hr class="divider-sm mb-st" />)}
                                                    <div class="form-row mb-20">
                                                        <div class="col-12 col-md-9">
                                                            <div id="agency_select_wrapper" class="form-group aep-custom moveable-label">
                                                                <div class="input-group">
                                                                    <select name="ctl00$cphContentMain$selectProgram$cphContentMain_selectProgram" defaultValue={pledgeInfo.selectProgram} id={'cphContentMain_selectProgram' + selectID++} ref={this.selectProgram} class="form-control custom-select has-value" onChange={(event) => this.handleChange(event.target.value, key, event.target.name)} data-field-type="dropdown" required={true} data-required="true" data-required-message="Please select a program">
                                                                        <option value="" class="blank">None</option>
                                                                        {this.state.response.programs.map((program) => (
                                                                            <option key={program.program_id} value={program.name}>
                                                                                {program.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    <label htmlFor={'cphContentMain_selectProgram' + selectID}>Select a Program</label>
                                                                    {(pledgeInfo.touchSelect && pledgeInfo.setErrorSelectProgram) && (<span id="cphContentMain_selectProgram_ctl02" class="invalid-feedback validator font-weight-bold error m-0 mb-2" data-validate-field="cphContentMain_selectProgram">{MakeAPledgeClass.MAKE_A_PLEDGE_SELECT_ERROR_MESSAGE}</span>)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-row mb-20">
                                                        <div class="col-12 col-md-4">
                                                            <div id="cphContentMain_pledge_amt_cphContentMain_pledge_amt_wrapper" class="form-group aep-custom moveable-label">
                                                                <div class="input-group">
                                                                    <NumericFormat thousandSeparator={true} prefix={'$ '} name="ctl00$cphContentMain$pledge_amt$cphContentMain_pledge_amt" required min="0" max="1500" step="0.01" id={'cphContentMain_pledge_amt' + key} class="form-control" data-field-type="number" defaultValue={Number(pledgeInfo.pledge_amt).toFixed(2)} ref={this.pledge_amt} placeholder="Pledge Amount" onValueChange={(values) => this.handleChangeAmount(values, key)} />
                                                                    <label htmlFor={'cphContentMain_pledge_amt' + key}>Pledge Amount</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {(pledgeInfo.touchAmount && pledgeInfo.setErrorPledgeAmount) && (<span id="cphContentMain_pledge_amt_ctl02" class="invalid-feedback validator font-weight-bold error m-0 mb-2" data-validate-field="cphContentMain_pledge_amt" style={{ paddingLeft: '10px' }}>
                                                            {MakeAPledgeClass.MAKE_A_PLEDGE_AMOUNT_ERROR_MESSAGE1}</span>)}
                                                    </div>


                                                    <div class="form-row mb-20">
                                                        <div class="col-12 col-md-6">
                                                            <div id="cphContentMain_transactionID_cphContentMain_transactionID_wrapper" class="form-group aep-custom moveable-label">
                                                                <div class="input-group">
                                                                    <input name="ctl00$cphContentMain$transactionID$cphContentMain_transactionID" type="text" id={'cphContentMain_transactionID' + key} class="form-control" data-field-type="text" defaultValue={pledgeInfo.transactionID} ref={this.transactionID} maxLength="10" onChange={(event) => this.handleChange(event.target.value, key, event.target.name)} placeholder="Agency Transaction ID" />
                                                                    <label htmlFor={'cphContentMain_transactionID' + key}>Agency Transaction ID</label>
                                                                </div>
                                                                <span id="cphContentMain_transactionID_ctl02" class="invalid-feedback validator font-weight-bold error m-0 mb-2" data-validate-field="cphContentMain_transactionID" style={{ "display": "none" }}></span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="form-row mb-20">
                                                        <div class="col-12 col-md-6">
                                                            <div id="cphContentMain_dtlcasedocid_cphContentMain_dtlcasedocid_wrapper" class="form-group aep-custom moveable-label">
                                                                <div class="input-group">
                                                                    <input name="ctl00$cphContentMain$dtlcasedocid$cphContentMain_dtlcasedocid" type="text" id={'cphContentMain_dtlcasedocid' + key} class="form-control" data-field-type="text" defaultValue={pledgeInfo.dtlcasedocid} ref={this.dtlcasedocid} maxLength="15" onChange={(event) => this.handleChange(event.target.value, key, event.target.name)} placeholder="Case Document ID" />
                                                                    <label htmlFor={'cphContentMain_dtlcasedocid' + key}>Case/Document ID</label>
                                                                </div>
                                                                <span id="cphContentMain_dtlcasedocid_ctl02" class="invalid-feedback validator font-weight-bold error m-0 mb-2" data-validate-field="cphContentMain_dtlcasedocid" style={{ "display": "none" }}></span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {!pledgeInfo.flagRemove && (<div class="form-row mb-40">
                                                        <div class="button col-12">
                                                            <button class="btn button-without-border mb-20 pl-1" onClick={this.addPledge} disabled={this.state.btnDisabledFlag} style={{ borderWidth: 0 }}>
                                                                <span class="mr-10">Add a pledge </span>
                                                                <img src={Add_Logo} alt="Add a Pledge Logo" style={{ width: '15px' }} />
                                                            </button>
                                                        </div>
                                                    </div>)}

                                                    {pledgeInfo.flagRemove && (<div class="form-row mb-40">
                                                        <div class="button col-12">
                                                            <button class="btn button-without-border mb-20 pl-1" onClick={this.removePledge.bind(this, key, pledgeInfo.id)} style={{ borderWidth: 0 }}>
                                                                <span class="mr-10">Remove this pledge</span>
                                                                <img src={Remove_Logo} alt="Remove a Pledge Logo" style={{ width: '18px' }} />
                                                            </button>
                                                        </div>
                                                    </div>)}
                                                </div>

                                            ))}

                                            <div class="row mb-10">
                                                <div class="col">
                                                    <h4 class="mb-40">Pledge Total: $ {numberWithCommas(this.state.totalPledgeAmount)}</h4>
                                                    <button class="btn btn-primary mr-20" onClick={this.submitFunction} disabled={this.state.noDataFlag || this.state.isDisabledFlag}>Review Pledge</button>
                                                </div>
                                            </div>
                                            <div class="row mb-10">
                                                <div class="col button">
                                                    <button class="btn button-without-border" onClick={this.cancelPledge} style={{
                                                        fontSize: '17px', padding: '0 0 17px',
                                                        textTransform: 'none', lineHeight: '2.3077', borderWidth: 0
                                                    }}>Cancel Pledge</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </span>

                            </div>
                        </div>
                    </span>
                </div>
            </section >
        )
    }
}


export default withRouter(MakeAPledge);
