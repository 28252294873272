import React, { Component } from 'react';
import { PatternFormat } from 'react-number-format';
import { returnDateTimeWithDashes, uniqueID } from '../global/formatter';
import { showSpinner } from './../global/loader';
import config from '../../config.json';
import { Auth } from "aws-amplify";
import StatesList from '../global/StatesList';
import { withRouter } from '../withRouter';
const defaultMsg = <p class="mb-0 error">We're sorry, something went wrong, please try again. If issue persists please contact <u class="color-dark-blue">agency@aep.com</u> for assistance.</p>;
const serviceHeader = {
  'Content-Type': config.agencyDBService.header.contentType,
  'x-api-key': config.agencyDBService.header.key,
  'Connection': config.agencyDBService.header.connection
};


class AddAgency extends Component {
  state = {
    fields: {
      errors: {},
      update_error: false,
      agency_name: "",
      touched: {},
      location_name: "",
      phone_num: "",
      isDisabledAddAgencyFlag: true,
      street_address1: "",
      street_address2: "None",
      city: "",
      state_code: "",
      zip_code: "",
      agency_type: ""

    },
    statearray: StatesList.statearray
  }

  setValidFlag = () => {
    let fields = this.state.fields;
    let flag = true;
    for (var key of Object.keys(fields["errors"])) {
      if (fields["errors"][key] !== '') {
        flag = false;
        break;
      }
    }
    if (flag && Object.keys(this.state.fields["touched"]).length === 9)
      fields["isDisabledAddAgencyFlag"] = false;
    else if (flag && Object.keys(this.state.fields["touched"]).length === 8 && !this.state.fields["touched"]['street_address2'])
      fields["isDisabledAddAgencyFlag"] = false;
    else
      fields["isDisabledAddAgencyFlag"] = true;
    this.setState({ fields });
  }

  validateField = (fieldName, value) => {
    let fields = this.state.fields;
    const isEmpty = value.length === 0;
    switch (fieldName) {
      case 'street_address1':
        if (isEmpty)
          fields["errors"]["street_address1"] = "Please enter street address.";
        else
          fields["errors"]["street_address1"] = "";
        break;
      case 'agency_name':
        if (isEmpty)
          fields["errors"]["agency_name"] = "Please enter agency name.";
        else
          fields["errors"]["agency_name"] = "";
        break;
      case 'location_name':
        if (isEmpty)
          fields["errors"]["location_name"] = "Please enter location name.";
        else
          fields["errors"]["location_name"] = "";
        break;
      case 'city':
        if (isEmpty)
          fields["errors"]["city"] = "Please enter city.";
        else
          fields["errors"]["city"] = "";
        break;
      case 'state_code':
        if (isEmpty)
          fields["errors"]["state_code"] = "Please select state.";
        else if (value === "")
          fields["errors"]["state_code"] = "Please select state.";
        else
          fields["errors"]["state_code"] = "";
        break;
      case 'zip_code':
        if (isEmpty)
          fields["errors"]["zip_code"] = "Please enter zip code.";
        else
          fields["errors"]["zip_code"] = "";
        break;
      case 'phone_num':
        if (isEmpty)
          fields["errors"]["phone_num"] = "Please enter a phone number.";
        else if (value.length < 10)
          fields["errors"]["phone_num"] = "Please enter 10 digits";
        else
          fields["errors"]["phone_num"] = "";
        break;
      case 'agency_type':
        if (isEmpty)
          fields["errors"]["agency_type"] = "Please select an agency role.";
        else
          fields["errors"]["agency_type"] = "";
        break;
      default:
        break;
    }
    this.setState({ fields }, () => {
      this.setValidFlag();
    });
  }

  cancelAddAgency = event => {
    this.props.navigate("/manageagencies");
  }

  handleChange = event => {
    const { name, value } = event.target;
    let fields = this.state.fields;
    fields[name] = value;
    fields["touched"][name] = true;
    console.log(":::::: updated values")
    console.log(this.state.fields);
    this.setState({ fields },
      () => { this.validateField(name, value) });
  }

  handleChangeState = event => {
    const { name, value } = event.target;
    let fields = this.state.fields;
    if (value !== "") {
      fields[name] = this.state.statearray[value].state;
      fields["touched"][name] = true;
      console.log(":::::: updated values")
      console.log(this.state.fields)
      this.setState({ fields },
        () => { this.validateField("state_code", this.state.statearray[value].state) });
    }
    else {
      this.setState({ fields },
        () => { this.validateField("state_code", value) });
    }
  }

  handleChangePhoneNum = (values) => {
    const { value } = values;
    let fields = this.state.fields;
    fields["phone_num"] = value;
    fields["touched"]["phone_num"] = true;
    this.setState({ fields },
      () => { this.validateField("phone_num", value) });
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false
      }
    });
  }

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  }

  rdsaddagency = () => {
    let tableName = "", approved_by_user = "", agencyType = "";// "GAA_USER"
    approved_by_user = String(this.props.auth.user["username"]).split(".")[2];
    if (this.state.fields.agency_type === "Private")
      agencyType = "P"
    else if (this.state.fields.agency_type === "Government")
      agencyType = "G"

    let agencyidgenerator = uniqueID();
    let agencyServiceEndpoint = new URL(config.agencyDBService.endpoint);
    let formattedquery1 = "INSERT into agency.agency (agency_id,name,status,created_by,created_date,last_update_user,last_update_timestamp,agency_type) values (" + Number(agencyidgenerator) + ",'" + String(this.state.fields.agency_name).replaceAll("'","''").trim() + "','A','" + approved_by_user + "','" + returnDateTimeWithDashes() + "','" + approved_by_user + "','" + returnDateTimeWithDashes() + "','" + agencyType + "')"
    console.log(formattedquery1)
    const params2 = {
      TableName: tableName, CommandType: "CUSTOMES", Query: formattedquery1
    }
    Object.keys(params2).forEach(key => agencyServiceEndpoint.searchParams.append(key, params2[key]));

    (async () => {
      try {
        const response1 = await fetch(agencyServiceEndpoint, {
          method: 'POST',
          headers: serviceHeader
        });
        const json_resp = await response1.json();
        console.log(json_resp);
        if (json_resp.Status === undefined) {
          if (json_resp["ResponseMetadata"].HTTPStatusCode === 200)
            this.callUpdateLocation(agencyidgenerator, approved_by_user);
          else
            this.callError();
        }
        else {
          if (json_resp.Status.toLowerCase().includes('fail'))
            this.callError();
        }
      }
      catch (error) {
        console.log(error);
        this.callError();
      }
    })();
  }

  callError = () => {
    showSpinner(false);
    this.setState({update_error: true});
  }

  callUpdateLocation = (agencyidgenerator, approved_by_user) => {
    let tableName = "";
    let formattedquery2 = "INSERT into agency.agency_location (agency_location_id,agency_id,name,street_address1,street_address2,city,state,zip_code,status,user_access_review_date,created_by,created_date, last_update_user,last_update_timestamp,phone) values (" + Number(agencyidgenerator) + 1 + "," + Number(agencyidgenerator) + ",'" + String(this.state.fields.location_name).replaceAll("'","''").trim() + "','" + String(this.state.fields.street_address1).replaceAll("'","''").trim() + "','" + String(this.state.fields.street_address2).replaceAll("'","''").trim() + "','" + String(this.state.fields.city).replaceAll("'","''").trim() + "','" + this.state.fields.state_code + "','" + this.state.fields.zip_code + "','A','" + returnDateTimeWithDashes() + "','" + approved_by_user + "','" + returnDateTimeWithDashes() + "','" + approved_by_user + "','" + returnDateTimeWithDashes() + "','" + this.state.fields.phone_num + "')"
    let agencyServiceEndpoint = new URL(config.agencyDBService.endpoint);
    const params3 = {
      TableName: tableName, CommandType: "CUSTOMES", Query: formattedquery2
    }

    Object.keys(params3).forEach(key => agencyServiceEndpoint.searchParams.append(key, params3[key]));
    (async () => {
      try {
        const response2 = await fetch(agencyServiceEndpoint, {
          method: 'POST',
          headers: serviceHeader
        });
        const json_resp2 = await response2.json();
        console.log(json_resp2);
        if (json_resp2.Status === undefined) {
          if (json_resp2["ResponseMetadata"].HTTPStatusCode === 200) {
            showSpinner(false);
            this.props.navigate("/manageagencies", {state: { addAgencyUpdate: true }});
          }          
          else
            this.callError();
        }
        else {
          if (json_resp2.Status.toLowerCase().includes('fail'))
            this.callError();
        }
      }
      catch (error) {
        console.log(error);
        this.callError();
      }
    })();
  }

  handleAddAgency = () => {
    showSpinner(true);
    this.rdsaddagency();
  }

  async componentDidMount() {
    showSpinner(true);
    await Auth.currentAuthenticatedUser();
    let userGroup = "";
    const groupName = config.cognito.USER_POOL_ID + '_aep';
    if (this.props.auth.user != null) {
      userGroup = this.props.auth.user.signInUserSession.accessToken.payload['cognito:groups'];
      if (String(userGroup) !== groupName) {
        this.props.navigate("/accountsummary");
      }
      showSpinner(false);
    }
}

  render() {
    return (
      <section className="section">
        <div id="main" class="container">
          {this.state.update_error &&
            <div class="row">
              <div class="col-12 error mb-30">
                <div class="error">
                  <span class="mb-0">{defaultMsg}</span>
                </div>
              </div>
            </div>
          }
          <span id="cphContentMain_GlobalUserControl1">
            <h1>ADD AGENCY</h1>
            <div id="cphContentMain_ctl00_NotTexasDiv">
              <div class="col-sm-10 col-md-10 col-xl-10 no-margins">
                <span id="cphContentMain_ctl00_GlobalUserControl1">
                  <div id="cphContentMain_ctl00_ctl00_PnlPledgeForm">
                    <div role="form" class="bordered-form">
                      <h3 class="mb-40">Please select "Add agency" once complete.</h3>
                      <div class="wrapper-block">
                        <div class="form-row mb-35">
                          <div class="col-12 col-md-8">
                            <div id="cphContentMain_agency_name_cphContentMain_agency_name_wrapper" class="form-group aep-custom moveable-label" style={{ marginBottom: 0 }}>
                              <div class="input-group">
                                <input name="agency_name" type="text" required id='cphContentMain_agency_name' class="form-control" data-field-type="text" value={this.state.fields["agency_name"]} onChange={this.handleChange} placeholder="Agency Name" />
                                <label htmlFor='cphContentMain_agency_name'>Agency Name*</label>
                              </div>
                              {this.state.fields["errors"]["agency_name"] !== '' && (<span id="cphContentMain_agency_name_ctl02" class="invalid-feedback validator error font-weight-bold m-0 mb-2" data-validate-field="cphContentMain_agency_name" >{this.state.fields["errors"]["agency_name"]}</span>)}
                            </div>
                          </div>
                        </div>
                        <div class="form-row mb-35">
                          <div class="col-12 col-md-8">
                            <div id="cphContentMain_location_name_cphContentMain_location_name_wrapper" class="form-group aep-custom moveable-label" style={{ marginBottom: 0 }}>
                              <div class="input-group">
                                <input name="location_name" type="text" required id='cphContentMain_location_name' class="form-control" data-field-type="text" value={this.state.fields["location_name"]} onChange={this.handleChange} placeholder="Location Name" />
                                <label htmlFor='cphContentMain_location_name'>Location Name*</label>
                              </div>
                              <span class="form-field-helper">You may add associated locations after creating a primary agency.</span>
                              {this.state.fields["errors"]["location_name"] !== '' && (<span id="cphContentMain_location_name_ctl02" class="invalid-feedback validator error font-weight-bold m-0 mb-2" data-validate-field="cphContentMain_location_name" >{this.state.fields["errors"]["location_name"]}</span>)}
                            </div>
                          </div>
                        </div>
                        <div class="form-row mb-20">
                          <div class="col-12 col-sm-6">
                            <div id="cphContentMain_street_address1_cphContentMain_street_address1_wrapper" class="form-group aep-custom moveable-label">
                              <div class="input-group">
                                <input name="street_address1" required type="text" id='cphContentMain_street_address1' class="form-control" data-field-type="text" value={this.state.fields["street_address1"]} onChange={this.handleChange} placeholder="Street Address" />
                                <label htmlFor='cphContentMain_street_address1'>Street Address*</label>
                              </div>
                              {this.state.fields["errors"]["street_address1"] !== '' && (<span id="cphContentMain_street_address1_ctl02" className="error invalid-feedback validator font-weight-bold m-0 mb-2" data-validate-field="cphContentMain_street_address1" >{this.state.fields["errors"]["street_address1"]}</span>)}
                            </div>
                          </div>
                          <div class="col-12 col-sm-6">
                            <div id="cphContentMain_street_address2_cphContentMain_street_address2_wrapper" class="form-group aep-custom moveable-label">
                              <div class="input-group">
                                <input name="street_address2" required type="text" id='cphContentMain_street_address2' class="form-control" data-field-type="text" value={this.state.fields["street_address2"]} onChange={this.handleChange} placeholder="Street Address2" />
                                <label htmlFor='cphContentMain_street_address1'>Optional Street Address</label>
                              </div>
                              <span class="form-field-helper">This is usually an apt. suite, unit, etc.</span>
                              {this.state.fields["errors"]["street_address2"] !== '' && (<span id="cphContentMain_street_address2_ctl02" className="error invalid-feedback validator font-weight-bold m-0 mb-2" data-validate-field="cphContentMain_street_address2" >{this.state.fields["errors"]["street_address2"]}</span>)}
                            </div>
                          </div>
                        </div>
                        <div class="form-row mb-20">
                          <div class="col-12 col-sm-6">
                            <div id="cphContentMain_city_cphContentMain_city_wrapper" class="form-group aep-custom moveable-label">
                              <div class="input-group">
                                <input name="city" required type="text" id='cphContentMain_city' class="form-control" data-field-type="text" value={this.state.fields["city"]} onChange={this.handleChange} placeholder="City" />
                                <label htmlFor='cphContentMain_city'>City*</label>
                              </div>
                              {this.state.fields["errors"]["city"] !== '' && (<span id="cphContentMain_city_ctl02" className="error invalid-feedback validator font-weight-bold m-0 mb-2" data-validate-field="cphContentMain_city" >{this.state.fields["errors"]["city"]}</span>)}
                            </div>
                          </div>
                          <div class="col-6 col-sm-3">
                            <div id="agency_state_code_wrapper" class="form-group aep-custom moveable-label" style={{ marginBottom: 0 }}>
                              <div className="input-group">
                                <select
                                  class="form-control"
                                  name="state_code"
                                  id="state_code"
                                  placeholder="State"
                                  data-field-type="dropdown"
                                  onChange={this.handleChangeState}
                                  required
                                >
                                  <option value="" class="blank">-</option>
                                  {this.state.statearray.map((row, key) => (
                                    <option key={key} value={key}>
                                      {row.state}
                                    </option>
                                  ))}
                                </select>
                                <label htmlFor="state_code" class="align-label">State</label>
                              </div>
                              {this.state.fields["errors"]["state_code"] !== '' && (<span id="state_code_span" class="invalid-feedback validator error font-weight-bold m-0 mb-2" data-validate-field="cphContentMain_state_code" >{this.state.fields["errors"]["state_code"]}</span>)}
                            </div>
                          </div>
                          <div class="col-6 col-sm-3">
                            <div id="cphContentMain_zip_code_cphContentMain_zip_code_wrapper" class="form-group aep-custom moveable-label">
                              <div class="input-group">
                                <input name="zip_code" required type="number" id='cphContentMain_zip_code' class="form-control" data-field-type="number" value={this.state.fields["zip_code"]} onChange={this.handleChange} placeholder="Zip Code" />
                                <label htmlFor='cphContentMain_zip_code'>Zip Code*</label>
                              </div>
                              {this.state.fields["errors"]["zip_code"] !== '' && (<span id="cphContentMain_zip_code_ctl02" className="error invalid-feedback validator font-weight-bold m-0 mb-2" data-validate-field="cphContentMain_zip_code" >{this.state.fields["errors"]["zip_code"]}</span>)}
                            </div>
                          </div>
                        </div>
                        <div class="form-row mb-20">
                          <div class="col-12 col-md-5">
                            <div id="cphContentMain_phone_num_cphContentMain_phone_num_wrapper" class="form-group aep-custom moveable-label">
                              <div class="input-group">
                                <PatternFormat format="+1 (###) ###-####" mask="_" name="phone_num" required id='cphContentMain_phone_num' class="form-control" data-field-type="number" value={this.state.fields["phone_num"]} placeholder="Phone Number" onValueChange={(values) => this.handleChangePhoneNum(values)} />
                                <label htmlFor='cphContentMain_phone_num'>Phone Number*</label>
                              </div>
                              {this.state.fields["errors"]["phone_num"] !== '' && (<span id="cphContentMain_phone_num_ctl02" class="invalid-feedback validator error font-weight-bold m-0 mb-2" data-validate-field="cphContentMain_phone_num" > {this.state.fields["errors"]["phone_num"]}</span>)}
                            </div>
                          </div>
                        </div>
                        <div clas="role-group-wrapper">
                          <h4 class="mb-20">Agency Type</h4>
                          <div role="group" aria-labelledby="directionRadio">
                            <div class="selector-wrap">
                              <span id="rbl_1" data-field-type="radio-button-list" value={this.state.fields["agency_type"]} data-required="true" data-required-message="Please choose a value" class="radio" required >
                                <input id="rbl_1_1" type="radio" name="agency_type" value="Private" onClick={this.handleChange} defaultChecked={this.state.fields["agency_type"] === 'Private'} />
                                <label htmlFor="rbl_1_1">Private</label>

                                <input id="rbl_1_0" type="radio" name="agency_type" value="Government" onClick={this.handleChange} defaultChecked={this.state.fields["agency_type"] === 'Government'} />
                                <label htmlFor="rbl_1_0">Government</label>

                              </span>
                              {this.state.fields["errors"]["agency_type"] && (<span id="cphContentMain_rbl_1_ctl01" class="invalid-feedback validator error font-weight-bold m-0 mb-2" data-validate-field="rbl_1" >{this.state.fields["errors"]["agency_type"]}</span>)}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-10">
                        <div class="col">
                          <button class="btn btn-primary mr-20" onClick={this.handleAddAgency} disabled={this.state.fields['isDisabledAddAgencyFlag']}>Add Agency</button>
                        </div>
                      </div>
                      <div class="row mb-10">
                        <div class="col button">
                          <button class="btn button-without-border" onClick={this.cancelAddAgency} style={{
                            fontSize: '17px', padding: '0 0 17px',
                            textTransform: 'none', lineHeight: '2.3077', borderWidth: 0
                          }}>Cancel</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </span>
        </div>
      </section >
    )
  }
}


export default withRouter(AddAgency);