import React, { Component } from 'react';
import "../styles/banner.scss";

class Banner extends Component {
    render() {
        const tbStyle = {
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            borderBottom: '1px solid #9e9fa1',
            bottom: '0',
            position: 'absolute',
            width: '100%'
        }

        return (
            <div>
                <div class="banner-container home">
                    <div id="bannerImage" class="banner-image home crop-right" style={{ position: 'relative' }}>
                        <div class="container" style={{ height: '100%' }}>
                            <div class="row d-flex align-items-center" style={{ height: '100%' }} id="bannerContentHome">
                                <div class="col-6 col-md-7 col-lg-8 col-xl-6 banner-contents home">
                                    <div class="d-block mt-n-50 mt-md-n75 mt-lg-n100">
                                        <div class="h1 banner-title white">People Helping People</div>
                                        <h4 class="d-block banner-description white">American Electric Power strives to bring comfort to our customers, support business and customers, and build strong communities by helping those in need pay their bills.</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-none d-md-block" style={tbStyle}>
                            <div class="container">
                                <div class="task-bar home">
                                    <div class="task-bar-item pl-0">
                                        <a href="/accountsummary">
                                            <span class="ai ai-heart-o filter-dark-blue"></span>
                                            <span class="">MAKE A PLEDGE</span>
                                        </a>
                                    </div>
                                    <div class="task-divider"></div>

                                    {/* <div class="task-bar-item">
                                        <a href="/accountsummary">
                                            <span class="ai ai-calendar-o filter-link-blue"></span>
                                            <span class="shrink-wrap-text">SCHEDULE AN APPOINTMENT</span>
                                        </a>
                                    </div> */}
                                    <div class="task-divider"></div>

                                    <div class="task-bar-item">
                                        <a href="/reports">
                                            <span class="ai ai-report-o filter-orange"></span>
                                            <span class="shrink-wrap-text">AGENCY REPORTS</span>
                                        </a>
                                    </div>
                                    <div class="task-divider"></div>

                                    <div class="task-bar-item">
                                        <a href="/reviewregistrationrequests">
                                            <span class="ai ai-user-o filter-green"></span>
                                            <span class="">AGENCIES & USERS</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container d-block d-md-none mt-st ml-n15">
                    <div class="row no-gutters">
                        <div id="tbpledge" class="col-6 mb-15">
                            <a href="/accountsummary/" title="MAKE A PLEDGE" class="task-link border border-dark-blue rounded">
                                <span class="ai ai-heart-o home filter-dark-blue"></span>
                                <span class="color-dark-gray">MAKE A <span class="task-text">PLEDGE</span></span>
                            </a>
                        </div>
                        {/* <div id="tbschedule" class="col-6 mb-15">
                            <a href="/accountsummary/" title="schedule an appointment" class="task-link border border-link-blue rounded">
                                <span class="ai ai-calendar-o home filter-link-blue"></span>
                                <span class="color-dark-gray">SCHEDULE AN <span class="task-text">APPOINTMENT</span></span>
                            </a>
                        </div> */}
                        <div id="tbreports" class="col-6">
                            <a href="/accountsummary/" title="agency reports" class="task-link border border-orange rounded">
                                <span class="ai ai-report-o home filter-orange"></span>
                                <span class="color-dark-gray">AGENCY <span class="task-text">REPORTS</span></span>
                            </a>
                        </div>
                        <div id="tbchoice" class="col-6">
                            <a href="/accountsummary/" title="electric choice" class="task-link border border-green rounded">
                                <span class="ai ai-user-o home filter-green"></span>
                                <span class="color-dark-gray">AGENCY <span class="task-text">& USERS</span></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default Banner;