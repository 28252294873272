import React, { Component } from "react";
import SuccessMark from '../../lib/images/svgs/ICON_circle-check_green.svg';
import { addHypensAccountNum, numberWithCommas } from '../global/formatter';
import { Auth } from 'aws-amplify';
import { showSpinner } from './../global/loader';
import { withRouter } from "../withRouter";

class EPAConfirmation extends Component {

    state = {
        noDataFlag: true,
        program: 'energyAssitance'
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.location.state !== undefined && this.props.location.state !== null)
            this.setState({ noDataFlag: false, program: this.props.location.state.program || 'energyAssitance', originalState: this.props.location.state.originalState });
    }

    makeAnotherPledge = () => {
      // set 'fromAccSumm' to true so programs load
			try {
				this.props.navigate('/makeapledge', {state: {
					'addresssummaryresp': this.props.location.state.addresssummaryresp,
					'addresslookupresp': this.props.location.state.addresslookupresp,
					'selectedAddress': this.props.location.state.selectedAddress,
					'originalState': this.state.originalState,
					'fromAccSumm': true
				}});
			} catch (err) {
				console.log(err);
				this.handleLogOut();
			}
		}

    changeToAccountSearch = () => {
        try{
        this.props.navigate('/accountsummary', {state: this.state.originalState});
        }catch(err) {
            console.log(err);
            this.handleLogOut();
        }
    }

    handleLogOut = async () => {
        showSpinner(true);
        try {
          await Auth.signOut({ global: true });
          this.setState({ hideProfile: true, hideManageUsers: true, hideReports: true, hideAssistance: true });
          this.props.auth.setAuthStatus(false);
          this.props.auth.setUser(null);
          this.props.navigate("/login");
        } catch (error) {
          console.log(error.message);
          await Auth.signOut();
          this.setState({ hideProfile: true, hideManageUsers: true, hideReports: true, hideAssistance: true });
          this.props.auth.setAuthStatus(false);
          this.props.auth.setUser(null);
          this.props.navigate("/login");
        }
        showSpinner(false);
      }

    render() {
        return (
          <div>
              <h1>{this.state.program === 'pippApplicationHold' ? 'PIPP Application Hold' : 'Energy Assistance'}</h1>
              <div className="col-sm-10 col-md-8 bordered-form wrapper-block">
                  <div className="mb-50">
                      <img className="float-left" src={SuccessMark} alt="Success Mark"></img>
                      <h3 className="float-left ml-3 mt-1">
                        {this.state.program === 'pippApplicationHold' ? 'PIPP Application Hold Success.' : 'The winter moratorium protection has been added.'}
                         Thank you!</h3>
                  </div>

                  <div className="form-row mb-15 clear-both">
                      <div className="col">
                          <h4 style={{ paddingTop: '20px' }}>Customer Details:</h4>
                      </div>
                  </div>
                  <div className="form-row">
                      <div className="col">
                          <div className="input-group two-block">
                              <p className="first">Name:</p>
                              <p className="second">{!this.state.noDataFlag && (<span>{this.props.location.state.addresssummaryresp["CustomerData"].cust_1st_nm} {this.props.location.state.addresslookupresp.CustMiddlenm} {this.props.location.state.addresssummaryresp["CustomerData"].cust_last_nm} {this.props.location.state.addresslookupresp.CustSuffixnm}</span>)}</p>
                          </div>
                      </div>
                  </div>
                  <div className="form-row">
                      <div className="col">
                          <div className="input-group two-block">
                              <p className="first">Service Address:</p>
                              <p className="second">{!this.state.noDataFlag && (<span>{this.props.location.state.selectedAddress.HouseNumber} {this.props.location.state.selectedAddress.StreetName} {this.props.location.state.selectedAddress.StreetDsgt}</span>)}</p>
                          </div>
                      </div>
                  </div>
                  <div className="form-row mb-20">
                      <div className="col">
                          <div className="input-group two-block">
                              <p className="first"></p>
                              <p className="second">{!this.state.noDataFlag && (<span>{this.props.location.state.selectedAddress.City} {this.props.location.state.selectedAddress.State} {this.props.location.state.selectedAddress.ZipCode}</span>)}</p>
                          </div>
                      </div>
                  </div>
                  <div className="form-row">
                      <div className="col">
                          <div className="input-group two-block">
                              <p className="first">Account Number:</p>
                              <p className="second">{!this.state.noDataFlag && (<span>{addHypensAccountNum(this.props.location.state.selectedAddress.AccountNum)+ '-'+ this.props.location.state.selectedAddress.BillCheckDigitNb}</span>)}</p>
                          </div>
                      </div>
                  </div>
                  <div className="form-row">
                      <div className="col">
                          <div className="input-group two-block">
                              <p className="first">Submitted Date:</p>
                              <p className="second">{!this.state.noDataFlag && (<span> {this.props.location.state.dateSubmitted}</span>)}</p>
                          </div>
                      </div>
                  </div>
                  <hr className="divider-sm mt-25 mb-35" />
                  <button className="btn btn-primary mr-20" onClick={this.makeAnotherPledge}>
                      Make a pledge
                   </button>
                  <button className="btn btn-secondary" onClick={this.changeToAccountSearch}>
                      View customer details
                   </button>
              </div>
          </div>
        )
    }
}

export default withRouter(EPAConfirmation);