//refer  to screenshot details
import React, { Component } from "react";
import { addHypensAccountNum, translateDate, dateWithDashes, uniqueID, returnTimestamp,addBusinessDays,addCalendarDays,dateWithSlashes } from '../global/formatter';
import conf from '../../config.json';
import { showSpinner } from './../global/loader';
import { Auth } from 'aws-amplify';
import { withRouter } from "../withRouter";

const serviceHeader = {
    'Content-Type': conf.agencyDBService.header.contentType,
    'x-api-key': conf.agencyDBService.header.key,
    'Connection': conf.agencyDBService.header.connection
};
var agencyServiceEndpoint = new URL(conf.agencyDBService.endpoint);


class ReviewAppointment extends Component {
    state = {
        noDataFlag: true,
        isDisabledFlag: true,
        agency_details: [],
        error_display: false
    }
    handleLogOut = async () => {
        showSpinner(true);
        try {
          await Auth.signOut({ global: true });
          this.setState({ hideProfile: true, hideManageUsers: true, hideReports: true, hideAssistance: true });
          this.props.auth.setAuthStatus(false);
          this.props.auth.setUser(null);
          this.props.navigate("/login");
        } catch (error) {
          console.log(error.message);
          await Auth.signOut();
          this.setState({ hideProfile: true, hideManageUsers: true, hideReports: true, hideAssistance: true });
          this.props.auth.setAuthStatus(false);
          this.props.auth.setUser(null);
          this.props.navigate("/login");
        }
        showSpinner(false);
      }
    handleRedirect = () => {
        try{
        if (!this.state.noDataFlag) {
          this.props.navigate('/scheduleappointment', {state: {
            'addresssummaryresp': this.props.location.state.addresssummaryresp,
            'addresslookupresp': this.props.location.state.addresslookupresp,
            'selectedAddress': this.props.location.state.selectedAddress,
            'selectedDate': this.props.location.state.selectedDate,
            'appointment': this.state.appointment,
            'fromAccSumm': true,
            'originalState': this.props.location.state.originalState,
            'appointmentTypes': this.props.location.state.originalState.appointmentTypes
          }});
        }
      } catch(err) {
          console.log(err);
          this.handleLogOut();
      }
    }

    submitFunction = async event => {
        showSpinner(true);
        this.setState({ disabledFlag: true });
        this.checkApptFromShadowService();
    }

    checkApptFromShadowService() {
        const sampleHeaders = {
            'Content-Type': 'application/json',
            'x-api-key': conf.accountSearchShadowService.token
        };
        const shadowservicegenericURL = new URL(conf.shadowServiceGenericURL.url + "addcreditextension");
        const newextensiondt = addBusinessDays(this.props.location.state.selectedDate,5);
        const newappointmentdt = addCalendarDays(this.props.location.state.selectedDate,30);
        const apptID = this.props.location.state.appointmentID;
        console.log("apptID:", Number(apptID), " ","isApptId900:", Number(apptID) === 900 ? true : false);
        let extensiondt, extensiontype;
        if (Number(apptID) === 900) {
          extensiondt = dateWithDashes(newextensiondt);
          extensiontype = "CP";
        } else {
          extensiondt = dateWithDashes(newappointmentdt);
          extensiontype = "CA";
        }

        const params = {
            accnum: this.props.location.state.selectedAddress.AccountNum,
            extensionamt: this.props.location.state.addresssummaryresp["AmountsDue"].bal_due_at,
            extensiondt: extensiondt,
            paymentmethod: "O",
            agencypymtflag: "N",
            extensiontype: extensiontype

        };
        console.log(JSON.stringify(params) + "params checkApptFromShadowService");
        Object.keys(params).forEach(key => shadowservicegenericURL.searchParams.append(key, params[key]));
        (async () => {
            try {
                const response = await fetch(shadowservicegenericURL, {
                    method: 'POST',
                    headers: sampleHeaders
                })
                const json_resp = await response.json();
                console.log(json_resp);
                if (json_resp["returnCode"] === 'OK')
                    this.callRDSUpdate();
                else
                    this.callError();
            }
            catch (error) {
                this.callError();
                console.log(error);
            }
        })();
    }

    callError = () => {
        this.setState({ disabledFlag: false, error_display: true });
        showSpinner(false);
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        await Auth.currentAuthenticatedUser(); 
        if (this.props.location.state !== undefined) {
            showSpinner(true);
            this.setState({ noDataFlag: false, isDisabledFlag: false, originalState: this.props.location.state.originalState });
            this.callAgencyInformation();
        } else {
            console.log("logging out as current auth expired")
            this.handleLogOut();
        }
    }

    callAgencyInformation = () => {
        let agency_det = {};
        agency_det["locale"] =  this.props.auth.user["attributes"].locale;
        this.setState({
            agency_details: agency_det
        });
        showSpinner(false);
    }

    callRDSUpdate = () => {
        let tableName = ""// "GAA_USER"
        agencyServiceEndpoint = new URL(conf.agencyDBService.endpoint);
        console.log(this.props.auth["user"]);
        let user_id = this.props.auth["user"].username;
        let locale = this.state.agency_details.locale;
        if(locale == undefined) {
            locale =0;
            user_id = user_id.split(/[\s.]+/).pop()+"::"+this.props.auth["user"]["attributes"].email;
        }
        else
            user_id = this.props.auth["user"]["attributes"].email;
        const d = returnTimestamp();

        let formattedquery = "INSERT INTO agency.Appointment (appointment_id,created_date, entered_by, account_number, amount,agency_location_id, last_update_user, last_update_timestamp, account_first_name, account_last_name, appointment_type, program_id ) VALUES('" +
            uniqueID() + "', '" +
            dateWithSlashes(this.props.location.state.selectedDate) + "', '" +
            user_id+ "', '" +
            this.props.location.state.selectedAddress.AccountNum + "', '" +
            this.props.location.state.addresssummaryresp["AmountsDue"].bal_due_at + "', '" +
            locale+ "', '" +
            user_id+ "', '" +
            d + "', '" +
            String(this.props.location.state.addresssummaryresp["CustomerData"].cust_1st_nm).replaceAll("'", "''") + "', '" +
            String(this.props.location.state.addresssummaryresp["CustomerData"].cust_last_nm).replaceAll("'", "''") + "', '" +
            this.props.location.state.appointment + "', '" +
            this.props.location.state.appointmentID + "')"    
            ;
        console.log(formattedquery);
        const params2 = {
            TableName: tableName, CommandType: "CUSTOM", Query: formattedquery
        }
        Object.keys(params2).forEach(key => agencyServiceEndpoint.searchParams.append(key, params2[key]));
        (async () => {
            try {
                const response = await fetch(agencyServiceEndpoint, {
                    method: 'POST',
                    headers: serviceHeader
                });
                const json_resp = await response.json();
                console.log(json_resp);
                this.props.navigate("/confirmationappointment",
                    {state: {
                        'addresssummaryresp': this.props.location.state.addresssummaryresp,
                        'addresslookupresp': this.props.location.state.addresslookupresp,
                        'selectedAddress': this.props.location.state.selectedAddress,
                        'selectedDate': this.props.location.state.selectedDate,
                        'appointment': this.props.location.state.appointment,
						'originalState': this.state.originalState
                    }});
            }
            catch (error) {
                console.log(error);
                this.callError();
            }
            showSpinner(false);
        })();
    }

    cancelAppointment = event => {
      try{
          this.props.navigate('/accountsummary', {state: this.state.originalState});
          }catch(err) {
          console.log(err);
          this.handleLogOut();
      }
    }

    render() {
        return (
            <section class="section auth">
                <div id="main" class="container">
                    <h1>Schedule Appointment</h1>
                    <div id="cphContentMain_ctl00_Review_Pledge">
                        <div class="col-sm-10 col-md-10 col-xl-10 no-margins">
                            <span id="main_block">
                                <div role="form" class="bordered-form">
                                    {this.state.error_display &&
                                        <div class="row">
                                            <div class="col-12 error mb-30">
                                                <div class="error">
                                                    <p class="mb-0">We're sorry, something went wrong, please try again. If issue persists please contact <u class="color-dark-blue">agency@aep.com</u> for assistance.</p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div class="review_form_header mb-30">
                                        <h3 class="mb-20">Review your appointment details.</h3>
                                        <p> Unless you  <button class="button-anchor" onClick={this.handleRedirect}>need to make changes</button>, hit Submit Appointment.</p>
                                    </div>
                                    <div class="review_form_subheader">
                                        <div class="sub-block mb-15 width-adjustment">
                                            <p class="mb-10 font-weight-bold">Customer Details:</p>
                                            <div class="two-block agency-manage display-inline-block">
                                                <p class="first">Name:</p>
                                                <p class="second text-align-right">
                                                    {!this.state.noDataFlag &&
                                                        (<span>{this.props.location.state.addresssummaryresp["CustomerData"].cust_1st_nm} {this.props.location.state.addresslookupresp.CustMiddlenm} {this.props.location.state.addresssummaryresp["CustomerData"].cust_last_nm} {this.props.location.state.addresslookupresp.CustSuffixnm}
                                                        </span>)}
                                                </p>
                                            </div>
                                            <div class="two-block agency-manage display-inline-block">
                                                <p class="first">Service Address:</p>
                                                <p class="second text-align-right">
                                                    {!this.state.noDataFlag &&
                                                        (<span>
                                                            {this.props.location.state.selectedAddress.HouseNumber} {this.props.location.state.selectedAddress.StreetName} {this.props.location.state.selectedAddress.StreetDsgt} {this.props.location.state.selectedAddress.UnitDesignator} {this.props.location.state.selectedAddress.UnitNumber}
                                                        </span>)}
                                                </p>
                                            </div>
                                            <div class="two-block agency-manage display-inline-block">
                                                <p class="first"></p>
                                                <p class="second text-align-right">
                                                    {!this.state.noDataFlag &&
                                                        (<span>
                                                            {this.props.location.state.selectedAddress.City} {this.props.location.state.selectedAddress.State} {this.props.location.state.selectedAddress.ZipCode}
                                                        </span>)}
                                                </p>
                                            </div>
                                            <div class="mb-20 two-block agency-manage display-inline-block">
                                                <p class="first">Account Number:</p>
                                                <p class="second text-align-right">
                                                    {!this.state.noDataFlag && (<span>{addHypensAccountNum(this.props.location.state.selectedAddress.AccountNum) + '-' + this.props.location.state.selectedAddress.BillCheckDigitNb}</span>)}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="review_form_body">
                                        {!this.state.noDataFlag && (
                                            <div class="wrapper-block mb-40">
                                                <hr class="divider-sm mb-20" />
                                                <p class="mb-10 font-weight-bold">Appointment Details</p>
                                                <div class="sub-block width-adjustment" id="DivRowSelectDate">
                                                    <div class="input-group two-block agency-manage display-inline-block">
                                                        <p class="first mb-10">Appointment Date:</p>
                                                        <p class="second text-align-right mb-10">{translateDate(this.props.location.state.selectedDate)}</p>
                                                        <p class="first">Appointment Type:</p>
                                                        <p class="second text-align-right">{(this.props.location.state.appointment)}</p>
                                                    </div>
                                                </div>
                                            </div>)}
                                        <div class="row mb-10">
                                            <div class="col">
                                                <button class="btn btn-primary mr-20" onClick={this.submitFunction} disabled={this.state.noDataFlag || this.state.disabledFlag}>Submit Appointment</button>
                                            </div>
                                        </div>
                                        <div class="row mb-10">
                                            <div class="col button">
                                                <button class="btn button-without-border" onClick={this.cancelAppointment} style={{
                                                    fontSize: '17px', padding: '0 0 17px',
                                                    textTransform: 'none', lineHeight: '2.3077', borderWidth: 0
                                                }}>Cancel Appointment</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </span>
                        </div>

                    </div>
                </div>
            </section>
        )
    }
}


export default withRouter(ReviewAppointment);
