import React, { Component } from 'react';
import SuccessMark from '../../../lib/images/svgs/ICON_circle-check_green.svg';

class EmailValidationComplete extends Component {
    componentDidMount(){
        console.log("***STARTING**")
    }
    render() {
        return (
            <section class="section auth">
                <div class="container">
                    <div class="row mb-30 header-content">
                        <h2>Thank You</h2>
                    </div>
                    <div class="mb-20 body-content">
                        <div class="row mb-40">
                        <img class="float-left mr-10" src={SuccessMark} alt="Success Mark"></img>
                        <h3>Your email has been confirmed!</h3>
                        </div>
                        <div class="row"><p>Once you receive an email approving your registration request, you will be able</p></div>                    
                        <div class="row"><p>to&nbsp;<a href="/login">login</a>&nbsp; and utilize the AEP Agency website to research and assist customers in need.</p></div>
                    </div>
                </div>
            </section>
        )
    }
}

export default EmailValidationComplete;