import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import AccountSummary from './components/AccountSummary';
import config from './config'
import LogIn from './components/auth/LogIn';
import ForgotPassword from './components/auth/ForgotPassword';
import ForgotPasswordVerification from './components/auth/ForgotPasswordVerification';
import ForgotPasswordConfirmation from './components/auth/ForgotPasswordConfirmation';
import ChangePassword from './components/auth/ChangePassword';
import SSO from './components/sso';
import AddAgency from './components/ManageAgencies/AddAgency';
import AddLocation from './components/ManageAgencies/AddLocation';
import { Auth } from 'aws-amplify';
import ManageUsers from './components/ManageAgencies/ManageUsers';
import ReviewRegistrationRequests from './components/ManageAgencies/ReviewRegistrationRequests';
import MyProfile from './components/MyProfile/MyProfile';
import UpdateProfile from './components/MyProfile/UpdateProfile';
import UpdateUserDetails from './components/ManageAgencies/UpdateUserDetails';
import ManageAgencies from './components/ManageAgencies/ManageAgencies';
import UpdateLocationDetails from './components/ManageAgencies/UpdateLocationDetails';
import UpdateAgencyName from './components/ManageAgencies/UpdateAgencyName';
import MakeAPledge from './components/Pledge/MakeAPledge';
import ReviewPledge from './components/Pledge/ReviewPledge';
import PledgeConfirmation from './components/Pledge/PledgeConfirmation';
import AddEnergyAssistance from './components/EnergyAssistance/AddEnergyAssistance';
import EPAConfirmation from './components/EnergyAssistance/EPAConfirmation';
import Register from './components/auth/register/Register';
import AwaitingApprovalRegister from './components/auth/register/AwaitingApprovalRegister';
import EmailValidationComplete from './components/auth/register/EmailValidationComplete';
import EmailValidationExpired from './components/auth/register/EmailValidationExpired';
import ScheduleAppointment from './components/Appointment/ScheduleAppointment';
import ReviewAppointment from './components/Appointment/ReviewAppointment';
import ConfirmationAppointment from './components/Appointment/ConfirmationAppointment';
import Loader_Gif from './lib/images/svgs/GIF_bulb.gif';
import { Modal } from 'react-bootstrap';
import Reports from './components/Reports';
import Footer from './components/Footer';
import ConfirmSignUp from './components/auth/register/ConfirmSignUp';
import conf from './config.json';
import { ThemeProvider, makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material';
import PIPPApplicationHold from './components/PIPPApplicationHold/PIPPApplicationHold';
import AccountSummaryProvider from './components/AccountSummaryProvider/AccountSummaryProvider';

var timeout = undefined
const ua = window.navigator.userAgent;
const theme = createTheme();

class App extends Component {

  state = {
    isBrowserInvalid: false,
    isIE: false,
    isFirefox: false,
    isAuthenticated: false,
    isAuthenticating: true,
    user: null,
    group: null,
    aepAdminFailedLogin: false
  }

  setAuthStatus = authenticated => {
    this.setState({ isAuthenticated: authenticated })
  }
  setUser = user => {
    this.setState({ user: user })
  }

  mouseMoved = event => {

    clearTimeout(timeout);
    let twoHours = 7200000 // 7200000 milliseconds
    timeout = setTimeout(this.logOut, twoHours);
  }

  checkIfLoggedOutThenRedirect() {
    if (this.state == undefined) {
      if (window.location != "/login")
        window.location.assign("/login")
    }
    else {
      clearTimeout(timeout);
      if (window.location != "/login")
        timeout = setTimeout(this.checkIfLoggedOutThenRedirect, 2000);
    }
  }

  logOut = async event => {
    if (this.state.isAuthenticated) {
      console.log("Log out")
      try {
        await Auth.signOut({ global: true });
        this.setState(null);
        clearTimeout(timeout);
        timeout = setTimeout(this.checkIfLoggedOutThenRedirect, 2000);
      } catch (error) {
        console.log(error);
        await Auth.signOut();
        this.setState(null);
        clearTimeout(timeout);
        timeout = setTimeout(this.checkIfLoggedOutThenRedirect, 2000);
      }
    }
  }

  async componentDidMount() {

    // this will turn off logging across the site
    if (conf.debugConfig.LOGGING === "off")
      console.log = function () { };
    //

    try {
      console.log("UA: ", ua);
      if (window.MSCompatibleInfo != null) {
        this.setState({ isBrowserInvalid: true, isIE: true });
        throw new Error('IE Browser');
      }
      if (ua.includes("Firefox")) {
        this.setState({ isBrowserInvalid: true, isFirefox: true });
        throw new Error('Firefox Browser')
      }
      const session = await Auth.currentSession();
      this.setAuthStatus(true);
      console.log(session);
      const user = await Auth.currentAuthenticatedUser();
      this.setUser(user);

      console.log('user token in checkLogin: ', user.signInUserSession.accessToken.jwtToken); // Retrieves the token.
      let userGroup = user.signInUserSession.accessToken.payload['cognito:groups'];
      console.log("here is the group: " + userGroup)

      this.setState({ group: userGroup })
    }
    catch (error) {
      let errorDescription = new URLSearchParams(window.location.search).get("error_description");
      if (errorDescription != null && (errorDescription.includes("PreAuthentication failed") || (errorDescription).includes("PreSignUp failed"))) {
        this.setState({ aepAdminFailedLogin: true });
      }
      console.log(error)
    }
    this.setState({ isAuthenticating: false })
  }

  handleClose = () => {
    this.setState({ aepAdminFailedLogin: false });
  }

  close = () => {
    window.close();
  }

  render() {
    const authProps = {
      isAuthenticated: this.state.isAuthenticated,
      user: this.state.user,
      setAuthStatus: this.setAuthStatus,
      setUser: this.setUser
    }
    if (this.state.user) {
      console.log(this.state.user.attributes.email_verified)
    }

    let onMouseMove

    if (this.state.isAuthenticated) {
      onMouseMove = this.mouseMoved
      this.mouseMoved()

    }
    else
      onMouseMove = function () { };

    return (
      !this.state.isAuthenticating &&
      <ThemeProvider theme={theme}>
        <div onMouseMove={onMouseMove}>
          <Modal show={this.state.isBrowserInvalid} onHide={this.close} className='agency-modal-sm'>
            <Modal.Header closeButton>
              <Modal.Title>{this.state.isIE ? "Internet Explorer" : "Firefox"} is not supported.</Modal.Title>
            </Modal.Header>
            <Modal.Body><p>Please use <a href='https://www.microsoft.com/en-us/edge'>Microsoft Edge</a>, <a href='https://support.google.com/chrome/answer/95346?co=GENIE.Platform%3DDesktop&hl=en'>Chrome</a>, <a href='https://support.apple.com/downloads/safari'>Safari</a>  or another browser in order to view this website.</p>
              <p>By clicking an option above, you will be redirected to the supported browser's download page.</p>
              <p class='text-bold'>Please note, closing this modal will also close the web page.</p>
            </Modal.Body>
            <Modal.Footer>
              <button onClick={this.close} class="btn btn-primary">Close web page</button>
            </Modal.Footer>
          </Modal>
          <div class='loader centered-loader' id='progressSpinner'>
            <img src={Loader_Gif} alt="loader gif" class="loader" />
            <h1 class="span-text">Processing your Request</h1>
            <span class="font-weight-bold span-text span-text-small">Thank you for your patience.</span>
          </div>
          <div>
            <Modal show={this.state.aepAdminFailedLogin} onHide={this.handleClose} className="agency-modal-sm">
              <Modal.Header closeButton>
                <Modal.Title>Authorization required.</Modal.Title>
              </Modal.Header>
              <Modal.Body>You are not currently authorized to access this website. You may submit a MyAccess request for a Governmental Assistance Agency Website AEP Administrator if you need access.</Modal.Body>
              <Modal.Footer>
                <a href="http://myaccess/" class="btn btn-primary">Request access</a>
              </Modal.Footer>
            </Modal>
          </div>
          <AccountSummaryProvider>
            <Router forceRefresh>
              <Navbar auth={authProps} />
                <Routes>
                  <Route exact path="/login" element={
                    !this.state.isAuthenticated ? <LogIn auth={authProps} /> : 
                    <AccountSummary auth={authProps} />
                  } />
                  <Route exact path="/" element={
                    !this.state.isAuthenticated ? <LogIn auth={authProps} /> : 
                    <AccountSummary auth={authProps} />
                  } />
                  <Route exact path="/AccountSummary" element={
                    this.state.isAuthenticated ? <AccountSummary auth={authProps} /> :
                    <LogIn auth={authProps} />
                  } />
                  <Route exact path="/sso" element={
                    !this.state.isAuthenticated ? (<LogIn auth={authProps} />) : 
                    ((this.state.group == config.cognito.USER_POOL_ID + '_aep') && (<SSO auth={authProps} />))
                  } />
                  <Route exact path="/addagency" element={
                    !this.state.isAuthenticated ? <LogIn auth={authProps} /> : 
                    <div class="container mt-5"><AddAgency auth={authProps} /></div>
                  } />
                  <Route exact path="/addlocation" element={
                    !this.state.isAuthenticated ? <LogIn auth={authProps} /> : 
                    <div class="container mt-5"><AddLocation auth={authProps} /></div>
                  } />
                  <Route exact path="/makeapledge" element={
                    !this.state.isAuthenticated ? <LogIn auth={authProps} /> :
                    <div class="container mt-5"><MakeAPledge auth={authProps} /></div>
                  } />
                  <Route exact path="/myprofile" element={
                    !this.state.isAuthenticated ? <LogIn auth={authProps} /> : 
                    <MyProfile auth={authProps} />
                  } />
                  <Route exact path="/updateprofile" element={
                    !this.state.isAuthenticated ? <LogIn auth={authProps} /> : 
                    <UpdateProfile auth={authProps} />
                  } />
                  {/* Do we want to nest these items, example... /agency-user/users */}
                  <Route exact path="/manageusers" element={
                    !this.state.isAuthenticated ? <LogIn auth={authProps} /> : 
                    <ManageUsers auth={authProps} />
                  } />
                  {/* Do we want to nest these items, example... /agency-user/review */}
                  <Route exact path="/reviewregistrationrequests" element={
                    !this.state.isAuthenticated ? <LogIn auth={authProps} /> : 
                    <ReviewRegistrationRequests auth={authProps} />
                  } />
                  {/* Do we want to nest these items, example... /agency-user/agencies */}
                  <Route exact path="/manageagencies" element={
                    !this.state.isAuthenticated ? <LogIn auth={authProps} /> : 
                    <ManageAgencies auth={authProps} />
                  } />
                  <Route exact path="/updatelocationdetails" element={
                    !this.state.isAuthenticated ? <LogIn auth={authProps} /> : 
                    <UpdateLocationDetails auth={authProps} />
                  } />
                  <Route exact path="/UpdateAgencyName" element={
                    !this.state.isAuthenticated ? <LogIn auth={authProps} /> : 
                    <UpdateAgencyName auth={authProps} />
                  } />
                  {/* Do we want to nest these items, example... /agency-user/users/update */}
                  <Route exact path="/updateuserdetails" element={
                    !this.state.isAuthenticated ? <LogIn auth={authProps} /> : 
                    <UpdateUserDetails auth={authProps} />
                  } />
                  <Route exact path="/reviewpledge" element={
                    !this.state.isAuthenticated ? <LogIn auth={authProps} /> : 
                    (<div class="container mt-5"><ReviewPledge auth={authProps} /></div>)
                  } />
                  <Route exact path="/confirmationpledge" element={
                    !this.state.isAuthenticated ? <LogIn auth={authProps} /> : 
                    (<div class="container mt-5"><PledgeConfirmation auth={authProps} /></div>)
                  } />
                  <Route exact path="/scheduleappointment" element={
                    !this.state.isAuthenticated ? <LogIn auth={authProps} /> : 
                    (<div class="container mt-5"><ScheduleAppointment auth={authProps} /></div>)
                  } />
                  <Route exact path="/reviewappointment" element={
                    !this.state.isAuthenticated ? <LogIn auth={authProps} /> : 
                    (<div class="container mt-5"><ReviewAppointment auth={authProps} /></div>)
                  } />
                  <Route exact path="/confirmationappointment" element={
                    !this.state.isAuthenticated ? <LogIn auth={authProps} /> : 
                    (<div class="container mt-5"><ConfirmationAppointment auth={authProps} /></div>)
                  } />
                  <Route exact path="/addenergyassistance" element={
                    !this.state.isAuthenticated ? <LogIn auth={authProps} /> : 
                    (<div class="container mt-5"><AddEnergyAssistance auth={authProps} /></div>)
                  } />
                  <Route exact path="/pippapplicationhold" element={
                    !this.state.isAuthenticated ? <LogIn auth={authProps} /> : 
                    (<div class="container mt-5"><PIPPApplicationHold auth={authProps} /></div>)
                  } />
                  <Route exact path="/epaconfirmation" element={
                    !this.state.isAuthenticated ? <LogIn auth={authProps} /> : 
                    (<div class="container mt-5"><EPAConfirmation auth={authProps} /></div>)
                  } />
                  <Route exact path="/changepassword" element={
                    !this.state.isAuthenticated ? <LogIn auth={authProps} /> : 
                    (<div class="container mt-5"><ChangePassword auth={authProps} /></div>)
                  } />
                  <Route exact path="/forgotpasswordconfirmation" element={
                    !this.state.isAuthenticated ? <div class="container mt-5"><ForgotPasswordConfirmation auth={authProps} /></div> : 
                    <AccountSummary auth={authProps} />
                  } />
                  <Route exact path="/reports" element={
                    !this.state.isAuthenticated ? <LogIn auth={authProps} /> : 
                    <Reports auth={authProps} />
                  } />
                  <Route exact path="/register" element={
                    !this.state.isAuthenticated ? <Register auth={authProps} /> : 
                    <AccountSummary auth={authProps} />
                  } />
                  <Route exact path="/awaitingapproval" element={
                    !this.state.isAuthenticated ? <AwaitingApprovalRegister auth={authProps} /> : 
                    <AccountSummary auth={authProps} />
                  } />
                  <Route exact path="/emailvalidationcomplete" element={
                    !this.state.isAuthenticated ? <EmailValidationComplete auth={authProps} /> : 
                    <AccountSummary auth={authProps} />
                  } />
                  <Route exact path="/emailvalidationexpired" element={
                    !this.state.isAuthenticated ? <EmailValidationExpired auth={authProps} /> : 
                    <AccountSummary auth={authProps} />
                  } />
                  <Route exact path="/confirmsignup" element={
                    !this.state.isAuthenticated ? <ConfirmSignUp auth={authProps} /> : 
                    <AccountSummary auth={authProps} />
                  } />
                  <Route exact path="/forgotpassword" element={
                    !this.state.isAuthenticated ? <ForgotPassword auth={authProps} /> : 
                    <div class="container mt-5"><ChangePassword auth={authProps} /></div>
                  } />
                  <Route exact path="/forgotpasswordverification" element={
                    !this.state.isAuthenticated ? <ForgotPasswordVerification auth={authProps} /> : 
                    <AccountSummary auth={authProps} />
                  } />
                </Routes>
              <Footer auth={authProps} />
            </Router>
          </AccountSummaryProvider>
        </div>
      </ThemeProvider>
    );
  }
}


export default App;