import React, { Component } from "react";
import "./../Login.css";
import conf from './../../config';
import { Auth } from "aws-amplify";
import { reverseAgencyRole, addHypensPhonenumber, formatToMMDDYYYY } from "../global/formatter";
import * as AgencyRoleConstants from '../constants/AgencyRoleConstants';
import { showSpinner } from './../global/loader';
import MaterialTable from 'material-table';
import SuccessMark from '../../lib/images/svgs/ICON_circle-check_green.svg';
import { withRouter } from "../withRouter";

const serviceHeader = {
  'Content-Type': conf.agencyDBService.header.contentType,
  'x-api-key': conf.agencyDBService.header.key,
  'Connection': conf.agencyDBService.header.connection
};
var agencyServiceEndpoint = new URL(conf.agencyDBService.endpoint);
class ManageUsers extends Component {
  state = {
    users: {
      UserList: [],
      data: [
        {
          "name": "",
          "location": "",
          "role": "",
          "phone": "",
          "first_name": "",
          "last_name": "",
          "user_id": "",
          "street_address1": "",
          "street_address2": "",
          "city": "",
          "state": "",
          "zip_code": "",
          "approved_by": "",
          "approved_timestamp": "",
          "approved_user_flag": "",
          "last_login": "",
          "token": ""
        }
      ],
    },
    dataflag: 0,
    rowData: [],
    isAepAdmin: false
  }

  callAEPAdminData = () => {
    console.log("aep admin");
    const query = "SELECT distinct u.user_id, u.token, u.first_name, u.last_name, u.phone, u.role, u.approved_user_flag, u.approved_by, u.approved_timestamp, TRIM(u.last_login) as last_login, a.name, L.street_address1, L.street_address2, L.city, L.state, L.zip_code, L.name as location FROM Agency.gaa_user u INNER JOIN Agency.agency a on u.agency_id = a.agency_id INNER JOIN Agency.agency_location L ON a.agency_id = L.agency_id and L.agency_location_id = cast ( u.token_flag as int8)";
    this.loadUserData(query);
  }

  callAgencyAdminData = () => {
    console.log("agency admin");
    const agency_id = this.props.auth.user["attributes"].zoneinfo;
    const query = "SELECT distinct u.user_id, u.token, u.first_name, u.last_name, u.phone, u.role, u.approved_user_flag, u.approved_by, u.approved_timestamp, TRIM(u.last_login) as last_login, a.name, L.street_address1, L.street_address2, L.city, L.state, L.zip_code, L.name as location FROM Agency.gaa_user u INNER JOIN Agency.agency a on u.agency_id = a.agency_id INNER JOIN Agency.agency_location L ON a.agency_id = L.agency_id and L.agency_location_id = cast ( u.token_flag as int8) WHERE u.agency_id = '" + agency_id + "'";
    this.loadUserData(query);
  }

  showStatus = (status) => {
    let result="";
    switch(status) {
      case '':
        result = "Pending";
        break;
      case 'Y':
        result = "Active";
        break;
      default:
        result = "Pending";
        break;
    }
    return result;
  }

  loadUserData = (query) => {
    showSpinner(true);
    let tableName = "" //"GAA_USER"
    const params = {
      TableName: tableName, CommandType: "CUSTOM", Query: query
    }
    Object.keys(params).forEach(key => agencyServiceEndpoint.searchParams.append(key, params[key]));
    (async () => {
      try {
        const response = await fetch(agencyServiceEndpoint, {
          method: 'POST',
          headers: serviceHeader
        })
        const json_resp = await response.json();
        console.log('JSON: ', json_resp);
        if (!String(json_resp.Status).toLowerCase().includes("job failed")) {
          if (!json_resp.ResponseMetadata) {
            this.setState({
              users: {
                "UserList": await json_resp.map((x, index) => Object.assign({}, x,
                  {"username": [x.first_name, " ", x.last_name], "contact": [x.user_id, <br />, addHypensPhonenumber(x.phone)], "agency": [x.name, <br />, x.location], "role": reverseAgencyRole(x.role), "login": formatToMMDDYYYY(x.last_login), "status": this.showStatus(x.approved_user_flag) })),
              },

              dataflag: 1
            });
            console.log('users: ', this.state.users)
          }
        }
      }
      catch (error) {
        console.log(error);
      }
      showSpinner(false);
    })();
  }

  handleRowClick = (rowData) => {
    console.log('handlerowclick data: ', rowData);
    const { navigate } = this.props;
    this.setState({
      rowData: rowData,
      data: [{
        "name": rowData.name,
        "location": rowData.location,
        "first_name": rowData.first_name,
        "last_name": rowData.last_name,
        "user_id": rowData.user_id,
        "street_address1": rowData.street_address1,
        "street_address2": rowData.street_address2,
        "city": rowData.city,
        "state": rowData.state,
        "zip_code": rowData.zip_code,
        "role": rowData.role,
        "phone": rowData.phone,
        "approved_by": rowData.approved_by,
        "approved_timestamp": rowData.approved_timestamp,
        "approved_user_flag": rowData.approved_user_flag,
        "last_login": rowData.last_login,
        "token": rowData.token
      }]
    }, () => {
      navigate(
        '/updateuserdetails',
        { state: {
          name: rowData.name,
          location: rowData.location,
          first_name: rowData.first_name,
          last_name: rowData.last_name,
          user_id: rowData.user_id,
          street_address1: rowData.street_address1,
          street_address2: rowData.street_address2,
          city: rowData.city,
          state: rowData.state,
          zip_code: rowData.zip_code,
          role: rowData.role,
          phone: rowData.phone,
          approved_by: rowData.approved_by,
          approved_timestamp: rowData.approved_timestamp,
          approved_user_flag: rowData.approved_user_flag,
          last_login: rowData.last_login,
          token: rowData.token,
          isAepAdmin: this.state.isAepAdmin
        } }
      )
    });
  }

  async componentDidMount() {
    showSpinner(true);
    await Auth.currentAuthenticatedUser();
    let role = "", userGroup = "";
    const groupName = conf.cognito.USER_POOL_ID + '_aep';
    if (this.props.auth.user != null) {
      userGroup = this.props.auth.user.signInUserSession.accessToken.payload['cognito:groups'];
      role = this.props.auth.user["attributes"].nickname;
      console.log(this.props.location.state);
      if (String(role) === AgencyRoleConstants.AGENCY_USER_ROLE) {
        this.props.navigate("/accountsummary");
        showSpinner(false);
      }
      else if (String(role) === AgencyRoleConstants.AGENCY_ADMIN_ROLE)
        this.callAgencyAdminData();
      else if (String(userGroup) === groupName) {
        this.setState({isAepAdmin: true});
        this.callAEPAdminData();
      }
      else {
        this.props.navigate("/accountsummary");
        showSpinner(false);
      }
    }
  }

  render() {
    const rdata = this.state.users.UserList;
    const columns = [
      {
        title: "User Details",
        field: "username",
      },
      {
        title: "Contact Info",
        field: "contact",
      },
      {
        title: "Agency",
        field: "agency",
      },
      {
        title: "Role",
        field: "role",
      },
      {
        title: "Last Login",
        field: "last_login",
        render: rdata => formatToMMDDYYYY(rdata.last_login),
        defaultSort: "desc"
      },
      {
        title: "Status",
        field: "status",
      }
    ];

    const headerStyles = { backgroundColor: '#f1f1f1', fontSize: '1.2rem', fontWeight: 'bold' }

    return (
      <section>
        <div class="container mt-st material-table-css">
          {<div>
            <h1>Manage Users</h1>
            {(this.props.location.state !== undefined && this.props.location.state !== null && this.props.location.state.attributeUpdatedFlag) &&
              <div class="mb-10 display-inline-block">
                <img class="float-left" src={SuccessMark} alt="Success Mark"></img><p class="float-left mt-1 ml-20">Success! Your information has been successfully updated.</p>
              </div>
            }
            {(this.props.location.state !== undefined && this.props.location.state !== null && this.props.location.state.deleteUserFlag) &&
              <div class="mb-10 display-inline-block">
                <img class="float-left" src={SuccessMark} alt="Success Mark"></img><p class="float-left mt-1 ml-20">Success! The user has been successfully deleted.</p>
              </div>
            }
            {this.state.dataflag === 0 ? (<div>No user records to display</div>) : (<div>
              <MaterialTable
                class='table-agency-datatable'
                title=''
                data={this.state.users.UserList}
                columns={columns}
                onRowClick={(event, rowData) => this.handleRowClick(rowData)}
                options={{
                  search: true,
                  sorting: true,
                  headerStyle: headerStyles
                }}
              ></MaterialTable>
            </div>)}
          </div>}
        </div>
      </section>
    )
  }
}

export default withRouter(ManageUsers);

              // <br />
              // <table class="table-row-dividers w-100 manage-requests">
              //   <thead>
              //     <tr class="row ml-n1">
              //       <th class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
              //         <b>User Details</b>
              //       </th>
              //       <th class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
              //         <b>Agency</b>
              //       </th>
              //       <th class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
              //         <b>Location</b>
              //       </th>
              //       <th class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
              //         <b>Role</b>
              //       </th>
              //       <th class="col-xs-3 col-sm-3 col-md-3 col-lg-3"></th>
              //     </tr>
              //   </thead>
              //   <tbody>
              //     {Object.entries(this.state.users.UserList).map(([key, userinfo]) => (
              //       <tr class="row ml-n1 cursor-pointer" key={key} onClick={this.callUpdateUserDetails.bind(this, key, userinfo)}>
              //         <td class="col-xs-3 col-sm-3 col-md-3 col-lg-3" >
              //           <p>{userinfo.first_name},&nbsp;{userinfo.last_name}</p>
              //           <p>{userinfo.user_id}</p>
              //         </td>
              //         <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
              //           <span class="text-align-checkbox">{userinfo.name}</span>
              //         </td>
              //         <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
              //           <span class="text-align-checkbox">{userinfo.location}</span>
              //         </td>
              //         <td class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
              //           <span class="text-align-checkbox">{userinfo.roleformatted}</span>
              //         </td>
              //       </tr>
              //     ))}
              //   </tbody>
              // </table>
