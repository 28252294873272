
import React, { Component } from "react";
// import "./Login.css";
import SuccessMark from '../../lib/images/svgs/ICON_circle-check_green.svg';
import { addHypensAccountNum, numberWithCommas } from '../global/formatter';
import { showSpinner } from './../global/loader';
import { Auth } from 'aws-amplify';
import { withRouter } from "../withRouter";

class PledgeConfirmation extends Component {

    state = {
        noDataFlag: true
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        console.log(this.props.location.state.totalPledgeAmount + "total::::");
        if (this.props.location.state !== undefined && this.props.location.state !== null)
            this.setState({ noDataFlag: false });
    }
    handleLogOut = async () => {
        showSpinner(true);
        try {
          await Auth.signOut({ global: true });
          this.setState({ hideProfile: true, hideManageUsers: true, hideReports: true, hideAssistance: true });
          this.props.auth.setAuthStatus(false);
          this.props.auth.setUser(null);
          this.props.navigate("/login");
        } catch (error) {
          console.log(error.message);
          await Auth.signOut();
          this.setState({ hideProfile: true, hideManageUsers: true, hideReports: true, hideAssistance: true });
          this.props.auth.setAuthStatus(false);
          this.props.auth.setUser(null);
          this.props.navigate("/login");
        }
        showSpinner(false);
      }

    makeAnotherPledge = () => {
        const {location} = this.props;
        let state = { ...location.state };
        delete state.pledgeList;
        delete state.totalPledgeAmount;
        delete state.programs;
        delete state.agency_details;
        delete state.dateSubmitted;
        delete state.fromAccSumm;
        try{
        this.props.navigate('/makeapledge', {state: { 'selectedAddress': this.props.location.state.selectedAddress, 'addresssummaryresp': this.props.location.state.addresssummaryresp, 'addresslookupresp': this.props.location.state.addresslookupresp, 'fromAccSumm': true }});
        }catch(err) {
            console.log(err);
            this.handleLogOut();
        }
    }

    changeToAccountSearch = () => {
        try{
            this.props.navigate('/accountsummary', { replace: true });
        }catch(err) {
            console.log(err);
            this.handleLogOut();
        }
    }

    render() {
        return (
            <div className="container">
                <div>
                    <h1>MAKE A PLEDGE</h1>
                    <div class="col-9 bordered-form wrapper-block">
                        <div class="mb-60">
                            <img class="float-left" src={SuccessMark} alt="Success Mark"></img>
                            <h3 class="float-left ml-3 mt-1">Your pledge has been submitted. Thank you!</h3>
                        </div>

                        <div class="form-row mb-15 clear-both">
                            <div class="col">
                                <h4 style={{ paddingTop: '20px' }}>Customer Details:</h4>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col">
                                <div class="input-group two-block">
                                    <p class="first">Name:</p>
                                    <p class="second">{!this.state.noDataFlag && (<span>{this.props.location.state.addresssummaryresp["CustomerData"].cust_1st_nm} {this.props.location.state.addresslookupresp.CustMiddlenm} {this.props.location.state.addresssummaryresp["CustomerData"].cust_last_nm} {this.props.location.state.addresslookupresp.CustSuffixnm}</span>)}</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col">
                                <div class="input-group two-block">
                                    <p class="first"> Service Address:</p>
                                    <p class="second">{!this.state.noDataFlag && (<span>{this.props.location.state.selectedAddress.HouseNumber} {this.props.location.state.selectedAddress.StreetName} {this.props.location.state.selectedAddress.StreetDsgt} {this.props.location.state.selectedAddress.City} {this.props.location.state.selectedAddress.State} {this.props.location.state.selectedAddress.ZipCode}</span>)}</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col">
                                <div class="input-group two-block">
                                    <p class="first">Account Number:</p>
                                    <p class="second">{!this.state.noDataFlag && (<span>{addHypensAccountNum(this.props.location.state.selectedAddress.AccountNum)+ '-'+ this.props.location.state.selectedAddress.BillCheckDigitNb}</span>)}</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-row mb-20">
                            <div class="col">
                                <div class="input-group two-block">
                                    <p class="first">Submitted Date:</p>
                                    <p class="second">{!this.state.noDataFlag && (<span> {this.props.location.state.dateSubmitted}</span>)}</p>
                                </div>
                            </div>
                        </div>
                        <hr class="divider-sm mb-35" />

                        {Object.entries(this.props.location.state.pledgeList).map(([key, pledgeInfo]) => (

                            <div class="form-row mb-10" key={key}>
                                <div class="col">
                                <div class="input-group two-block">
                                <p class="first">{pledgeInfo.selectProgram}</p>
                                <p class="second"> $ {numberWithCommas(pledgeInfo.pledge_amt)}</p>
                                </div>
                                </div>
                            </div>
                        ))}

                        <div class="form-row mb-50">
                            <div class="col">
                                <div class="input-group two-block">
                                    <h4 class="first">Pledge Total: </h4>
                                    <p class="second">{(!this.state.noDataFlag && this.props.location.state.totalPledgeAmount !== undefined) && (<h4>$ {numberWithCommas(this.props.location.state.totalPledgeAmount)}</h4>)}</p>
                                </div>
                            </div>
                        </div>

                        <button class="btn btn-primary mr-20" onClick={this.makeAnotherPledge}>
                            Make another pledge
                         </button>
                        <button class="btn btn-secondary" onClick={this.changeToAccountSearch}>
                            View customer details
                         </button>


                    </div>
                </div>
            </div>

        )

    }

}

export default withRouter(PledgeConfirmation);