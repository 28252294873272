export const StatesList = {
    statearray:
    [{ "state": "AR" },
    { "state": "IN" },
    { "state": "KY" },
    { "state": "LA" },
    { "state": "MI" },
    { "state": "OH" },
    { "state": "OK" },
    { "state": "TN" },
    { "state": "TX" },
    { "state": "VA" },
    { "state": "WV" }
    ]
}

export default StatesList; 