import React from "react";

export default function SSO() {
    return (

<div >
           <div>
                       <h3>You logged in from SSO</h3>
                      </div>

                     
                      </div>
        



    );
}