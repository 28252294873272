import React, { Component } from 'react';
import {Auth} from 'aws-amplify';
import { withRouter } from '../../withRouter';

class ConfirmSignUp extends Component {
    componentDidMount(){
        console.log("***STARTING**")
            // https://cxagency-dev.auth.us-east-1.amazoncognito.com/confirmUser?client_id=7b8lf7qvcu4h9rh4uf9j1116jp&user_name=5af40422-d780-4419-9389-d299b3c75a5f&confirmation_code=269794__;!!H3PqUTRkow!qjcJq1R4fDG2IqccqikpIfI-zpq0iCSmcUe9Knq9pgNzRR1doh1c85bFNVdR$
    
        console.log(this.props.location.search)
        let user_name = new URLSearchParams(this.props.location.search).get("username")
        let confirmation_code = new URLSearchParams(this.props.location.search).get("confirmationCode")
        // let email = new URLSearchParams(this.props.location.search).get("email")
        if (user_name && confirmation_code ){
            console.log(user_name,confirmation_code)
            // const search_value = '?user_name=' + email
            const search_value = '?user_name=' + user_name
            new Promise((resolve,reject)=>{
                Auth.confirmSignUp(user_name,confirmation_code).then(
                 (data) => {
                   console.log(data) 
                   this.props.navigate('/emailvalidationcomplete');
                resolve(data)       },
                 (error) => {
                     console.log(error)
                     if(error.code === 'ExpiredCodeException'){
                        reject(error)
                        console.log(error.message)
                        this.props.navigate({pathname:'/emailvalidationexpired', search: search_value});
                     }
                     else{
                     reject(error)
                     this.props.navigate('/login');
                     }
                     
                 }
               );
               });
        }
        else {
            console.log("invalid parameters")
            this.props.navigate('/login');
        }
       
    }

    render() {
        return (
        
            <h></h>
        )
    }
}

export default withRouter(ConfirmSignUp);